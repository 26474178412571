@use "../../assets/css/index.scss" as *;

.actionGroup {
  @extend %flex-a-item-center;
  @include grid-gap($hydra-spacing-08);
  margin-left: auto;
  @include mediaQuery(lg) {
    width: 100%;
    margin-top: $hydra-spacing-24;
    > button,
    > .actionDP > button {
      width: 100%;
    }
  }
  button {
    justify-content: center;
    @include mediaQuery(lg) {
      font-size: $hydra-text-xs;
      min-height: 2.25rem;
    }
  }
  .actionDP {
    position: relative;
    @include mediaQuery(lg) {
      width: 100%;
    }
  }
}
.coreWrapper {
  @extend %flex;
  height: calc(100% - 81px);
  @include mediaQuery("lg") {
    height: auto;
    flex-direction: column;
    overflow: unset;
  }
}
.coreConsole {
  background-color: $primary300;
  padding: $hydra-spacing-16;
  width: 100%;
  overflow: auto;
  @extend %flex;
  @include grid-gap($hydra-spacing-16);
  height: 100%;
  .pageHeaderMobWrapper {
    overflow: auto;
    @extend %flex-a-item-start;
    @include column-gap($hydra-spacing-08);
    margin-bottom: $hydra-spacing-17;
    @include mediaQuery(lg, min-width) {
      display: none;
    }
  }
  .channelCardHeader {
    padding: $hydra-spacing-12 $hydra-spacing-14;
    border-bottom: $hydra-dim-01 solid $borderShade5;
    @extend %flex-a-item-center;
    @extend %flexShrink-0;
    background-color: $white;
    @include column-gap($hydra-spacing-08);
    cursor: pointer;
    min-width: 13.9375rem;
    border-radius: $hydra-radius-8;
    border: $hydra-dim-01 solid $borderShade4;

    @include mediaQuery(lg) {
      min-width: 6.9375rem;
    }
    &.adminCard {
      background-color: $green100;
      border: $hydra-dim-01 solid $successBorder;
    }
    &.editorCard {
      background-color: $primary600;
      border: $hydra-dim-01 solid $blue100;
    }
    &.viewerCard {
      background-color: $cream100;
      border: $hydra-dim-01 solid $cream200;
    }
    &.selected {
      border: $hydra-dim-02 solid $primary100;
      @include mediaQuery(lg) {
        border: $hydra-dim-01 solid $primary100;
      }
    }
    .logo {
      @extend %flex-a-j-center;
      img {
        width: 2rem;
        border-radius: $hydra-radius-6;
        height: 2rem;
      }
    }
    .channelTitleWrapper {
      @extend %flex-f-column;
      .channelTitle {
        font-size: $hydra-text-sm;
        font-weight: $hydra-font-semibold;
        line-height: 1.5;
        @extend %truncate;
        max-width: 8rem;
        color: $textColor8;
      }
      .channelPageCount {
        font-size: $hydra-text-xs;
        font-weight: $hydra-font-medium;
        line-height: 1.5;
        color: $textColor9;
      }
    }
    .externalLink {
      color: $primary100;
      margin-left: auto;
      transition: $hydra-transition-speed-3 all;

      a {
        &:hover {
          @extend %arrowLineExternalLine;
        }
      }
      svg {
        width: $hydra-spacing-22;
        height: $hydra-spacing-22;
        color: $gray700;
      }
    }
  }
  @include mediaQuery("lg") {
    background-color: $primary300;
    padding: $hydra-spacing-16 $hydra-spacing-20;
    flex-direction: column;
    overflow: unset;
  }
  .detailedConsole {
    width: 100%;
    overflow: auto;
    @extend %flex-f-column;
    @include row-gap($hydra-spacing-12);
    @include mediaQuery("lg") {
      @include row-gap($hydra-spacing-24);
      padding-bottom: $hydra-spacing-10;
    }
    @include mediaQuery("lg") {
      overflow: unset;
    }
  }
}
.userPermissionWrapper {
  @extend %flex-column;
  @include mediaQuery(lg) {
    padding: 0;
  }
}
@include emptyCard;
