$primary: #213c92;
$primaryLight: #4661b9;
$primaryMedium: #3488f4;
$primaryDark: #1877f2;
$primary100: #4765e5;
$primary200: #eef5ff;
$primary300: #fcfaf6;
$primary400: #F2F4FC;
$primary500: #CFD6F3;
$primary600: #F0F5FF;

$gray500: #708399;
$gray600: #d9d9d9;
$gray700: #A6A6A6;
$gray800: #B3C3D6;
$gray900: #515151;
$yellow100: #f6eee1;
$white: #ffffff;
$black: #000000;
$white100: #e4ebf5;
$white200: #f9f9f9;
$white300: #FAFAFA;
$borderShade1: #e4e4e4;
$borderShade2: #DCDCDC;
$borderShade3: #5D70AD;
$borderShade4: #DDDDDD;
$borderShade5: #EBEBEB;
$textColor1: #2c3642;
$textColor2: #98A5B4;
$textColor3: #6F6F6F;
$textColor4: #323C46;
$textColor5: #373737;
$textColor6: #666666;
$textColor7: #171c21;
$textColor8: #272727;
$textColor9: #576A80;
$textRedColor:#C03C3E;
$success: #3FBD61;
$successBg: #EAF7EE;
$successBorder: #C3E2CA;
$darkGreen:#008373;
$infoBg:#EFF3FA;
$infoBorder:#D1D4F5;
$infoColor:#2F80ED;
$restrictedBg:#FAEFF0;
$restrictedBorder:#FCD3D4;
$restrictedColor:#FC1A1A;
$cautionBg:#FEF3E8;
$cautionBorder:#EFDAC6;
$cautionColor:#F2994A;
$grayTooltipBg:#7A8299;
$selectBoxBorder:#AFB2B6;
$selectBoxInput:#343B49;
$backdrop:#0e121b38;
$green100:#ECF9EC;
$blue100:#DDE3FF;
$cream100:#FBF7EE;
$cream200:#F1E5D1;
/* font weight */
$hydra-font-extraLight: 200;
$hydra-font-light: 300;
$hydra-font-regular: 400;
$hydra-font-medium: 500;
$hydra-font-semibold: 600;
$hydra-font-bold: 700;
$hydra-font-extrabold: 800;
$hydra-font-black: 900;

/* font size */
$hydra-text-x: 0.625rem; /* 10px */
$hydra-text-xxs: 0.6875rem; /* 11px */
$hydra-text-xs: 0.75rem; /* 12px */
$hydra-text-sm: 0.875rem; /* 14px */
$hydra-text-base: 1rem; /* 16px */
$hydra-text-md: 1.063rem; /* 17px */
$hydra-text-lg: 1.125rem; /* 18px */
$hydra-text-xl: 1.25rem; /* 20px */
$hydra-text-1xl: 1.375rem; /* 22px */
$hydra-text-2xl: 1.5rem; /* 24px */
$hydra-text-3xl: 1.625rem; /* 26px */
$hydra-text-4xl: 1.75rem; /* 28px */
$hydra-text-5xl: 1.875rem; /* 30px */
$hydra-text-6xl: 2rem; /* 32px */
$hydra-text-7xl: 2.125rem; /* 34px */
$hydra-text-8xl: 2.25rem; /* 36px */
$hydra-text-9xl: 2.375rem; /* 38px */
$hydra-text-10xl: 2.5rem; /* 40px */
$hydra-text-11xl: 2.625rem; /* 42px */
$hydra-text-12xl: 2.75rem; /* 44px */
$hydra-text-13xl: 2.875rem; /* 46px */
$hydra-text-14xl: 3rem; /* 48px */
$hydra-text-15xl: 3.125rem; /* 50px */

/* spacing */
$hydra-spacing-01: 0.063rem; /* 1px */
$hydra-spacing-02: 0.125rem; /* 2px */
$hydra-spacing-03: 0.188rem; /* 3px */
$hydra-spacing-04: 0.25rem; /* 4px */
$hydra-spacing-05: 0.313rem; /* 5px */
$hydra-spacing-06: 0.375rem; /* 6px */
$hydra-spacing-07: 0.438rem; /* 7px */
$hydra-spacing-08: 0.5rem; /* 8px */
$hydra-spacing-09: 0.563rem; /* 9px */
$hydra-spacing-10: 0.625rem; /* 10px */
$hydra-spacing-11: 0.6875rem; /* 11px */
$hydra-spacing-12: 0.75rem; /* 12px */
$hydra-spacing-13: 0.8125rem; /* 13px */
$hydra-spacing-14: 0.875rem; /* 14px */
$hydra-spacing-15: 0.9375rem; /* 15px */
$hydra-spacing-16: 1rem; /* 16px */
$hydra-spacing-17: 1.0625rem; /* 17px */
$hydra-spacing-18: 1.125rem; /* 18px */
$hydra-spacing-19: 1.188rem; /* 19px */
$hydra-spacing-20: 1.25rem; /* 20px */
$hydra-spacing-22: 1.375rem; /* 22px */
$hydra-spacing-24: 1.5rem; /* 24px */
$hydra-spacing-25: 1.5625rem; /* 25px */
$hydra-spacing-26: 1.625rem; /* 26px */
$hydra-spacing-27: 1.688rem; /* 27px */
$hydra-spacing-28: 1.75rem; /* 28px */
$hydra-spacing-30: 1.875rem; /* 30px */
$hydra-spacing-32: 2rem; /* 32px */
$hydra-spacing-34: 2.125rem; /* 34px */
$hydra-spacing-36: 2.25rem; /* 36px */
$hydra-spacing-38: 2.375rem; /* 38px */
$hydra-spacing-40: 2.5rem; /* 40px */
$hydra-spacing-44: 2.75rem; /* 44px */
$hydra-spacing-47: 2.9375rem; /* 47px */
$hydra-spacing-48: 3rem; /* 48px */
$hydra-spacing-56: 3.5rem; /* 56px */
$hydra-spacing-64: 4rem; /* 64px */
$hydra-spacing-70: 4.375rem; /* 70px */
$hydra-spacing-72: 4.5rem; /* 72px */
$hydra-spacing-80: 5rem; /* 80px */

/* dimensions */
$hydra-dim--30: -1.875rem; /* -30px */
$hydra-dim--10: -0.625rem; /* -10px */
$hydra-dim-01: 0.063rem; /* 1px */
$hydra-dim-02: 0.125rem; /* 2px */
$hydra-dim-03: 0.188rem; /* 3px */
$hydra-dim-04: 0.25rem; /* 4px */
$hydra-dim-05: 0.313rem; /* 5px */
$hydra-dim-06: 0.375rem; /* 6px */
$hydra-dim-07: 0.438rem; /* 7px */
$hydra-dim-08: 0.5rem; /* 8px */
$hydra-dim-09: 0.563rem; /* 9px */
$hydra-dim-10: 0.625rem; /* 10px */
$hydra-dim-11: 0.6875rem; /* 11px */
$hydra-dim-12: 0.75rem; /* 12px */
$hydra-dim-13: 0.8125rem; /* 13px */
$hydra-dim-14: 0.875rem; /* 14px */
$hydra-dim-15: 0.9375rem; /* 15px */
$hydra-dim-16: 1rem; /* 16px */
$hydra-dim-17: 1.0625rem; /* 17px */
$hydra-dim-18: 1.125rem; /* 18px */
$hydra-dim-20: 1.25rem; /* 20px */
$hydra-dim-40: 2.5rem; /* 40px */
$hydra-dim-50: 3.125rem; /* 50px */
$hydra-dim-276: 17.25rem; /* 276px */

/* breakpoints */
$hydra-xs: 0;
$hydra-sm: 36rem; //576px
$hydra-md: 48rem; //768px
$hydra-lg: 62rem; //992px
$hydra-xl: 75rem; //1,200px
$hydra-xxl: 83.5rem; //1,336px
$hydra-xxxl: 87.5rem; //1400px

// checkbox

$hydra-checkbox-size: 1.25rem; /* 20px */
//border radius
$hydra-radius-4: 0.25rem;
$hydra-radius-6: 0.375rem;
$hydra-radius-8: 0.5rem;
$hydra-radius-10: 0.625rem;
$hydra-radius-12: 0.75rem;
$hydra-radius-22: 1.375rem;
$hydra-radius-26: 1.625rem;
$hydra-radius-full: 50%;
//Transition speeds
$hydra-transition-speed-3: 0.3s;

//zindex levels
$hydra-zIndex-reset: 0;
$hydra-zIndex-1: 1;
$hydra-zIndex-2: 2;
$hydra-zIndex-3: 3;
$hydra-zIndex-header: 4; //should be maximum
$hydra-zIndex-dropdown: 4; //should be maximum

$hydra-zIndex-slide-panel-backdrop: 4; //should be maximum
$hydra-zIndex-slide-panel: 5; //should be maximum
$hydra-zIndex-drawer: 6;

$hydra-zIndex-modal-backdrop: 6; //should be maximum
$hydra-zIndex-modal-panel: 7; //should be maximum
// ===========Dont increase z index anymore================
$bodyFont:"Poppins",sans-serif;
