.workSpaceWrapper {
  @apply flex h-full;
  &.active {
    opacity: 1;
    @apply md:w-full;
  }
  &:not(.active) {
    @apply md:ml-0 md:hidden;
  }
}
