@use '../../assets/css/index.scss' as *;

.coreConsole {
  background-color: $primary300;
  padding: $hydra-spacing-16;
  width: 100%;
  overflow: auto;
  @extend %flex;

  column-gap: 6px;
  height: 100%;
  @include mediaQuery('lg') {
    padding: 0;
    background-color: $white;
    flex-direction: column;
    overflow: unset;
  }
  .detailedConsole {
    width: 100%;
    overflow: auto;
    @extend %flex-f-column;
    @include row-gap($hydra-spacing-12);
    @include mediaQuery('lg') {
      @include row-gap($hydra-spacing-24);
      padding-bottom: $hydra-spacing-10;
    }
    @media screen and (min-width: 992px) {
      padding-right: 6px;
    }
    .title {
      font-weight: $hydra-font-regular;
      font-size: $hydra-text-base;
      line-height: 1.5;
      text-transform: capitalize;
      color: $textColor7;
      @include mediaQuery('lg') {
        display: none;
      }
    }
    @include mediaQuery('lg') {
      overflow: unset;
    }
    .channelConsole {
      // height: 100%;
      @include mediaQuery('lg') {
        &.card {
          padding: 0;
          border: 0;
        }
      }
      .channelHeader {
        @extend %flex-a-item-center;
        margin-bottom: $hydra-spacing-16;
        @extend %flex-wrap;
        h3 {
          font-size: $hydra-text-sm;
          line-height: 1.2;
          font-weight: $hydra-font-medium;
          color: $textColor1;
        }
        .btnWrapper {
          @extend %flex;
          @include column-gap($hydra-spacing-16);
          margin-left: auto;
        }
        .viewAll {
          margin-left: auto;
        }
      }
      .channelBody {
        @extend %grid;
        @include grid-temp-columns(repeat(auto-fit, minmax(11.75rem, 1fr)));
        @include grid-gap($hydra-spacing-16);
        // @extend %flex-wrap;
        @include mediaQuery('lg') {
          @include grid-temp-columns(repeat(auto-fit, minmax(10.4rem, 1fr)));
          @include grid-gap($hydra-spacing-08);
        }
        .card {
          transition: $hydra-transition-speed-3 all;
          @apply cursor-pointer;
          &:hover {
            border-color: $primary100;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
            .externalLink {
              opacity: 1;
            }
            .cardFooter {
              .refreshBtn {
                width: 8.625rem;
                @extend %flex-a-j-center;
                border-radius: $hydra-radius-22;
                border-color: $primary100;
                @include mediaQuery('lg') {
                  width: 100%;
                }
                svg {
                  color: $primary100;
                }
                div {
                  width: 5.75rem;
                  overflow: hidden;
                  color: $primary100;
                  margin-left: $hydra-spacing-07;
                }
              }
            }
          }
          .cardHeader {
            @extend %flex-a-item-center;
            + .cardRow {
              margin-top: $hydra-spacing-12;
            }
            .cardHeaderDetails {
              @extend %flex-a-item-center;
              @include column-gap($hydra-spacing-08);
              min-width: 0;
              .logoImg {
                width: 1.75rem;
                height: 1.75rem;
                @extend %flexShrink-0;
                img {
                  border-radius: $hydra-radius-6;
                  width: 100%;
                  height: 100%;
                }
              }
              .cardTitle {
                font-size: $hydra-text-xs;
                font-weight: $hydra-font-semibold;
                line-height: 1.5;
                color: $black;
                // text-transform: capitalize;
                word-break: break-word;
                @extend %truncate;
              }
            }
          }
          .externalLink {
            color: $primary100;
            margin-left: auto;
            opacity: 0;
            transition: $hydra-transition-speed-3 all;

            a {
              &:hover {
                @extend %arrowLineExternalLine;
              }
            }
            svg {
              width: $hydra-dim-14;
              height: $hydra-dim-14;
              @include mediaQuery('lg') {
                color: $gray700;
              }
            }
            @include mediaQuery('xl') {
              opacity: 1;
            }
          }
          .cardRow {
            @extend %flex-a-item-center;
            @include column-gap($hydra-spacing-08);
            + .cardRow {
              margin-top: $hydra-spacing-06;
            }
            .legendIcon {
              svg {
                width: $hydra-dim-12;
                height: $hydra-dim-12;
                color: $textColor2;
              }
            }
            .legendTitle {
              color: $textColor3;
              font-size: $hydra-text-xs;
              line-height: 1.5;
              text-transform: capitalize;
              font-weight: $hydra-font-regular;
            }
          }
          .cardFooter {
            width: 100%;
            margin-top: $hydra-spacing-11;
            .refreshBtn {
              width: 1.75rem;
              height: 1.75rem;
              margin-left: auto;
              @extend %flex-a-j-center;
              border-radius: $hydra-radius-22;
              border: $hydra-dim-01 solid $gray500;
              transition: $hydra-transition-speed-3 all;
              &:disabled {
                cursor: not-allowed;
                background-color: rgba($color: $gray500, $alpha: 0.1);
              }
              &:active {
                background-color: rgba($color: $primary100, $alpha: 0.1);
              }
              svg {
                color: $gray500;
                width: $hydra-dim-14;
                height: $hydra-dim-14;
                transition: $hydra-transition-speed-3 all;
              }
              div {
                width: 0;
                overflow: hidden;
                transition: $hydra-transition-speed-3 all;
                font-size: $hydra-text-xxs;
                font-weight: $hydra-font-medium;
                line-height: 1.45;
                white-space: nowrap;
              }
              &:disabled {
                color: $gray500;
                border-color: $gray500;
                opacity: 0.4;
                cursor: not-allowed;
              }
              &:hover:not(:disabled) {
                color: $primaryMedium;
              }
              @include mediaQuery('xl') {
                width: 100%;
                max-width: 12rem;
                border-radius: $hydra-radius-22;
                border-color: $primary100;
                display: flex;
                align-items: center;
                &:disabled {
                  color: $gray500;
                  border-color: $gray500;
                  opacity: 0.4;
                  cursor: not-allowed;
                }
                &:hover:not(:disabled) {
                  color: $primaryMedium;
                }
                svg {
                  color: $primary100;
                }
                div {
                  width: 5.75rem;
                  overflow: hidden;
                  color: $primary100;
                  margin-left: $hydra-spacing-07;
                }
              }
            }
          }
        }
      }
    }
  }
}
.alertSummary {
  width: 19.75rem;
  @extend %flexShrink-0;
  @extend %flex-f-column;
  height: 100%;
  @include mediaQuery('lg') {
    width: 100%;
    height: auto;
    &.card {
      padding: 0;
      border: 0;
      margin-top: $hydra-spacing-14;
    }
  }
  .alertHeader {
    position: relative;
    @extend %flex-a-item-center;
    h3 {
      font-size: $hydra-text-xs;
      font-weight: $hydra-font-medium;
      color: $gray500;
      text-transform: capitalize;
      line-height: 1.2;
      @include mediaQuery('lg') {
        font-size: $hydra-text-sm;
        color: $textColor1;
      }
    }
    .viewAll {
      font-size: $hydra-text-xs;
      margin-left: auto;
    }
  }
  .alertList {
    margin-top: $hydra-spacing-08;
    overflow: auto;
    max-height: calc(100vh - 210px);
    overflow-x: hidden;
    .alertNoti {
      width: 2rem;
      height: 2rem;
      min-width: 2rem;
    }
    @include mediaQuery('lg') {
      margin-top: $hydra-spacing-12;
    }
    li {
      @extend %flex;
      padding: $hydra-spacing-10 0;
      @include column-gap($hydra-spacing-08);
      .alertContent {
        @extend %flex-f-column;
        .alertText {
          font-size: $hydra-text-xs;
          font-weight: $hydra-font-regular;
          color: $black;
          line-height: 1.5;
          margin-bottom: $hydra-spacing-05;
          span {
            display: inline;
            + .userTag {
              margin-right: $hydra-spacing-02;
              // Email ID truncating
              word-break: break-word;
            }
          }
          .userTag {
            color: $darkGreen;
            font-weight: $hydra-font-semibold;
            display: inline;
            line-height: 1.25;
            + span {
              margin-left: $hydra-spacing-02;
            }
          }
        }
        .date {
          font-size: $hydra-text-x;
          font-weight: $hydra-font-regular;
          color: $gray500;
        }
      }
      .alertIcon {
        width: 1.5rem;
        height: 1.5rem;
        min-width: 1.5rem;
        border-radius: $hydra-radius-full;
        border: $hydra-dim-01 solid;
        @extend %flex-a-j-center;
        margin-left: auto;
        @extend %flexShrink-0;
        svg {
          width: $hydra-dim-16;
          height: $hydra-dim-16;
        }
        &.info {
          background-color: $infoBg;
          svg {
            color: $infoColor;
          }
          border-color: $infoBorder;
        }
        &.restricted {
          background-color: $restrictedBg;
          svg {
            color: $restrictedColor;
          }
          border-color: $restrictedBorder;
        }
        &.caution {
          background-color: $cautionBg;
          svg {
            color: $cautionColor;
          }
          border-color: $cautionBorder;
        }
      }
    }
  }
}
.alertLoadMore {
  margin-left: 2.375rem;
  margin-top: 1.25rem;
  //use below if needed
  @include mediaQuery('lg', 'max-width') {
    margin-top: 0.625rem;
    margin-bottom: 1.125rem;
    margin-left: 2.125rem;
  }
}
.titleMob {
  font-size: $hydra-text-base;
  margin-bottom: $hydra-spacing-16;
}
.alert {
  @extend %alertComponent;
}
@include mediaQuery('lg', 'min-width') {
  .titleMob {
    display: none;
    line-height: 1.2;
    font-weight: $hydra-font-regular;
    color: $textColor1;
    text-transform: capitalize;
  }
  .alertMob {
    display: none;
  }
}
.alertMob {
  margin-bottom: $hydra-spacing-16;
}
.alertBoxWeb {
  margin-top: auto;
  @include mediaQuery('lg') {
    display: none;
  }
}
@include cardStyles;
.channelNoData {
  height: 100vh;
  @include mediaQuery('lg') {
    height: auto;
    max-height: 100vh;
    min-height: 10vh;
  }
  .channelBody {
    height: 100%;
  }
}

.alertSummary {
  padding-left: 0;
  padding-right: 0;
  .alertHeader {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  ul {
    padding-right: 0.5rem;
    padding-left: 1rem;
    margin-right: 0.5rem;
  }
  ::-webkit-scrollbar {
    @apply w-1;
  }
  ::-webkit-scrollbar {
    @apply w-1;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    @apply bg-transparent;
  }
  &::-webkit-scrollbar-track {
    @apply bg-transparent;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    @apply rounded-[30px] bg-gray-light5;
  }
  ::-webkit-scrollbar-thumb {
    @apply rounded-[30px] bg-gray-light5;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.alertBanner {
  display: block;
  width: 100%;
  border: 1px solid #c03c3e;
  color: #c03c3e;
  font-size: $hydra-text-xs;
  padding: 8px 24px;
  margin-bottom: 10px;
  text-align: center;
  font-weight: $hydra-font-semibold;
  background: #faeff0;
  border-radius: 10px;
  margin-top: 12px;
}
