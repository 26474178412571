@use "../../assets/css/index.scss" as *;
.breadcrumbWrapper {
  @extend %flex;
  padding: $hydra-spacing-16;
  height: fit-content;
  width: 100%;
  border-bottom: $hydra-dim-01 solid $borderShade4;
  @include mediaQuery(lg) {
    padding: $hydra-spacing-16 $hydra-spacing-20;
    flex-direction: column;
  }
  .breadcrumbLayer {
    @extend %flex-f-column;
    .breadRow {
      @extend %flex;
      + .breadRow {
        margin-top: $hydra-spacing-06;
        @include mediaQuery(lg) {
          margin-top: 0;
          // margin-left: $hydra-spacing-38;
        }
      }
      .navigate {
        @include mediaQuery(lg) {
          margin-right: $hydra-spacing-14;
        }
        svg {
          color: $gray500;
          width: $hydra-dim-18;
          height: $hydra-dim-18;
          @include mediaQuery(lg) {
            width: $hydra-spacing-24;
            height: $hydra-spacing-24;
          }
        }
        &+ .userName{
          margin-left: 12px;
        }
       
      }
      .pathList {
        @extend %flex;
        @extend %flex-wrap;
        @include grid-gap($hydra-spacing-12 $hydra-spacing-20);
        @include mediaQuery(lg) {
          grid-row-gap: 0;
        }
        li {
          position: relative;
          @apply flex items-center;
          &:not(:first-child) {
            &:before {
              content: "";
              background-color: $gray800;
              height: $hydra-dim-04;
              border-radius: $hydra-radius-full;
              width: $hydra-dim-04;
              position: absolute;
              // top: $hydra-spacing-10;
              left: (-($hydra-spacing-12));
            }
          }
          &.highlight {
            .pathName {
              color: $textColor8;
              font-weight: $hydra-font-semibold;
              @include mediaQuery(lg) {
                font-size: $hydra-text-sm;
              }
            }
            &+li{
              @include mediaQuery(lg) {
                width: 100%;
                order: 1;
                &::before{
                  display: none;
                }
                div{
                  max-width: 100%;
                }
              }
            }
          }
          .pathName {
            font-weight: $hydra-font-regular;
            font-size: $hydra-text-sm;
            line-height: 1.5;
            color: $textColor9;
            @extend %truncate;
            max-width: 12rem;
            @include mediaQuery(lg) {
              max-width: 8rem;
              font-size: $hydra-text-base;
            }
          }
        }
      }
      .userName {
        @extend %flex;
        @include grid-gap($hydra-spacing-04);
        .userType {
          font-size: $hydra-text-xs;
          line-height: 1.5;
          font-weight: $hydra-font-regular;
          color: $textColor9;
          &.highlight{
            color: $textColor8;
            font-weight: $hydra-font-semibold;
            font-size: $hydra-text-sm;
          }
        }
        .verificationStatus {
          cursor: pointer;
          svg {
            width: $hydra-dim-16;
            height: $hydra-dim-16;
            color: $gray500;
          }
        }
      }
    }
  }
}
