@use "../../assets/css/index.scss" as *;

.coreConsole {
  background-color: $primary300;
  padding: 0;
  width: 100%;
  overflow: auto;
  @extend %flex-f-column;
  @include column-gap($hydra-spacing-12);
  height: 100%;
  @include mediaQuery("lg") {
    padding: 0;
    background-color: $white;
    flex-direction: column;
    overflow: unset;
  }
  .coreHeader {
    background-color: $white;
    padding: $hydra-spacing-16;
    @extend %flex-a-item-center;
    border-bottom: $hydra-dim-01 solid $borderShade4;
    @include mediaQuery(lg) {
      padding: $hydra-spacing-16 $hydra-spacing-20;
    }
    h3 {
      font-weight: $hydra-font-semibold;
      font-size: $hydra-text-sm;
      color: $textColor8;
    }
  }
  .coreCard {
    padding: $hydra-spacing-17;
    @include mediaQuery(lg) {
      padding: 0;
      border: 0;
      height: 100%;
      display: flex;
      flex-direction: column;
      .card {
        border: 0;
        background-color: $primary300;
        height: 100%;
      }
    }
    .reportWrapper {
      display: flex;
      justify-content: center;
      flex-direction: column;
      width: 700px;
      margin: 0 auto;
      @include mediaQuery(lg) {
        width: 100%;
      }
      .reportRow {
       width: 100%;
        @extend %flex;
        @include column-gap($hydra-spacing-16);
        @extend %formControlStyles;
        @include mediaQuery(lg) {
          flex-wrap: wrap;
          @include row-gap($hydra-spacing-24);
          width: 100%;
        }
        .information {
          font-size: $hydra-text-xs;
          color: $gray500;
          font-weight: $hydra-font-regular;
          max-width: 36.8125rem;
        }
        .viewAll {
          min-height: 2rem;
          max-width: 100%;
          width: 100%;
          @extend %flex-a-j-center;
          @include mediaQuery(lg){
            max-width: 100%;
            min-height: 2.75rem;
          }
        }
        &:first-child {
          margin-bottom: $hydra-spacing-32;
          @include mediaQuery(lg) {
            display: grid;
            @include grid-temp-columns(1fr 1fr);
            margin-bottom: $hydra-spacing-24;
          }
        }
        margin-bottom: $hydra-spacing-16;
        @include mediaQuery(lg) {
          margin-bottom: $hydra-spacing-24;
        }
        .formControlWrapper,
        .formControl {
          max-width: 100%;
          width: 100%;
          @include mediaQuery(lg) {
            max-width: 100%;
            .formControlLabel{
              margin-bottom: $hydra-spacing-08;
              color: $textColor8;
            }
          }
          &.receipient {
            // max-width: 23rem;
            position: relative;
            @include mediaQuery(lg) {
              max-width: 100%;
            }
          }
          .formInput,
          [class*="hydra-dropdown__control"] {
            min-height: 2.625rem;
            background-color: $white;
          }
        }
        .formControlWrapper {
          + .formControlWrapper {
            margin-top: 0;
          }
        }
        .reportType {
          max-width: 100%;
        }
      }
    }
  }
}

@include cardStyles;

.messagingWrapper {
  @include mediaQuery(lg) {
    padding: 0;
    height: 100%;
  }
}
@include mediaQuery(lg) {
  .dvOnly {
    display: none;
  }
}

@include mediaQuery(lg, min-width) {
  .mvOnly {
    display: none;
  }
}
