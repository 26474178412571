@use "../../assets/css/index.scss" as *;
.contentWrapper {
    margin-left: 4.0625rem;
    @extend %flex-f-column;
    height: 100vh;
    @include mediaQuery("lg") {
      margin-left: 0;
    }
  }
  .dashboardWrapper {
    height: calc(100vh - 3.25rem);
    @extend %flex;
    @include mediaQuery("xl") {
      height: 100%;
      flex-direction: column;
    }
    @include mediaQuery("lg") {
      // height: auto;
      padding: $hydra-spacing-16 $hydra-spacing-20;
    }
  }