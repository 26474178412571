@layer components {
  .modal-header {
    &::before {
      @apply absolute top-0 left-0 h-[6.25rem] w-[6.25rem] bg-no-repeat content-[''];
      background-image: url("data:image/svg+xml,%3Csvg width='97' height='87' viewBox='0 0 97 87' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='28.5' cy='18.5' r='68.5' fill='%233D52AC'/%3E%3C/svg%3E");
    }
    &::after {
      @apply absolute bottom-0 right-0 h-16 w-[6.5rem] bg-no-repeat content-[''];
      background-image: url("data:image/svg+xml,%3Csvg width='104' height='64' viewBox='0 0 104 64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clipPath='url(%23clip0_557_19051)'%3E%3Ccircle cx='68.5' cy='68.5' r='68.5' fill='%233D52AC'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_557_19051'%3E%3Crect width='137' height='64' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
    }
  }
  .login-modal-header {
    &::before {
      @apply absolute top-[-1.25rem] left-[-1.25rem] h-[6.25rem] w-[6.25rem] bg-no-repeat content-[''];
      background-image: url("data:image/svg+xml,%3Csvg width='97' height='87' viewBox='0 0 97 87' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='28.5' cy='18.5' r='68.5' fill='%233D52AC'/%3E%3C/svg%3E");
    }
    &::after {
      @apply absolute bottom-[-0.938rem] right-[-1.25rem] h-16 w-[6.5rem] bg-no-repeat content-[''];
      background-image: url("data:image/svg+xml,%3Csvg width='104' height='64' viewBox='0 0 104 64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clipPath='url(%23clip0_557_19051)'%3E%3Ccircle cx='68.5' cy='68.5' r='68.5' fill='%233D52AC'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_557_19051'%3E%3Crect width='137' height='64' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
    }
  }
  .alert-modal-header {
    svg {
      height: 50px;
    }
    &::before {
      @apply absolute top-[-1.25rem] left-[-1.25rem] h-[6.25rem] w-[6.25rem] bg-no-repeat opacity-5 content-[''];
      background-image: url("data:image/svg+xml,%3Csvg width='97' height='87' viewBox='0 0 97 87' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='28.5' cy='18.5' r='68.5' fill='%233D52AC'/%3E%3C/svg%3E");
    }
    &::after {
      @apply absolute bottom-[-0.938rem] right-[-1.25rem] h-16 w-[6.5rem] bg-no-repeat opacity-5 content-[''];
      background-image: url("data:image/svg+xml,%3Csvg width='104' height='64' viewBox='0 0 104 64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clipPath='url(%23clip0_557_19051)'%3E%3Ccircle cx='68.5' cy='68.5' r='68.5' fill='%233D52AC'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_557_19051'%3E%3Crect width='137' height='64' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
    }
  }
  .checkboxCustom {
    input + div svg {
      display: none !important;
    }
    input:checked + div {
      @apply cursor-pointer border-primary bg-primary;
      svg {
        display: block !important;
      }
    }
  }
  .user-details-grid {
    label {
      @apply relative mb-2 overflow-hidden text-ellipsis whitespace-nowrap text-left text-xs font-semibold leading-[1.125rem] text-gray-medium;
    }
    input {
      @apply h-12 rounded-lg border border-solid border-gray-dark4 py-3 px-4 text-sm font-medium leading-[1.375rem] text-gray-dark;
    }
  }

  .password-container {
    li {
      @apply relative text-xs font-normal leading-[1.125rem] text-gray-dark;
    }
  }

  .channelTickIcon {
    span {
      @apply text-gray-medium;
      padding-left: 5px;
    }
  }

  .emptyTick {
    @apply text-gray-medium;
    svg {
      width: 17px;
      height: 17px;
    }
    span {
      @apply text-gray-medium;
      padding-left: 5px;
    }
  }

  .payment-inputs {
    label {
      @apply relative mb-2 overflow-hidden text-ellipsis whitespace-nowrap text-left text-xs font-semibold leading-[1.125rem] text-gray-medium;
    }
    input {
      @apply h-12 rounded-lg border border-solid border-gray-medium py-3 px-4 text-sm font-medium leading-[1.375rem] text-gray-dark;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type='number'] {
      -moz-appearance: textfield;
    }
  }
  .footer-buttons {
    button {
      @apply flex h-11 flex-col items-center justify-center rounded-3xl py-2.5 text-sm font-semibold leading-6;
      min-width: 152px;
    }
  }
  @-webkit-keyframes rotating /* Safari and Chrome */ {
    from {
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes rotating {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  .rotating {
    -webkit-animation: rotating 2s linear infinite;
    -moz-animation: rotating 2s linear infinite;
    -ms-animation: rotating 2s linear infinite;
    -o-animation: rotating 2s linear infinite;
    animation: rotating 2s linear infinite;
  }
  .pricingPlan {
    @apply px-20 sm:px-0;
    ul {
      @apply pb-5;
      li {
        @apply bg-no-repeat p-0 pl-4.5 text-xs font-normal leading-[15px];
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='15' height='16' viewBox='0 0 15 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M7.36161 14.2625C10.6845 14.2625 13.4033 11.5438 13.4033 8.22087C13.4033 4.89795 10.6845 2.1792 7.36161 2.1792C4.0387 2.1792 1.31995 4.89795 1.31995 8.22087C1.31995 11.5438 4.0387 14.2625 7.36161 14.2625Z' fill='%2327AE60'/%3e%3cpath d='M5.54907 8.52452L6.53659 9.42936L9.17407 7.0127' stroke='white' strokeWidth='1.61111' strokeLinecap='round' strokeLinejoin='round'/%3e%3c/svg%3e ");
        background-position: top -2px left 0px;

        & + li {
          @apply mt-3;
        }
      }
      @media screen and (max-width: 992px) {
        padding-bottom: 24px;
        li {
          @apply max-w-full;

          & + li {
            @apply mt-2;
          }
        }
      }
    }
    .active {
      @apply w-72;
      transition: all 0.5s ease;
      transform: scale(1.1);
      h3,
      h2,
      span {
        @apply text-white;
      }
      ul {
        li {
          @apply max-w-fit pl-6.5 text-xs leading-[18px];
          background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M9 16.5C13.125 16.5 16.5 13.125 16.5 9C16.5 4.875 13.125 1.5 9 1.5C4.875 1.5 1.5 4.875 1.5 9C1.5 13.125 4.875 16.5 9 16.5Z' fill='white'/%3e%3cpath d='M6.75 9.37674L7.97589 10.5L11.25 7.5' stroke='%231877F2' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/%3e%3c/svg%3e ");
          background-position: top -2px left 0px;
          background-position: top -1px left 0px;

          & + li {
            @apply mt-3.5;
          }
        }
        @media screen and (max-width: 992px) {
          li {
            font-size: 10px !important;
            line-height: 15px !important;
            max-width: 100% !important;

            & + li {
              @apply mt-2;
            }
          }
        }
      }
      @media screen and (max-width: 992px) {
        width: 100% !important;
        transform: none !important;
      }
    }
    .pricingList {
      @apply w-full;
    }
  }
  .landing-page-footer {
    @apply bg-no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg width='1366' height='320' viewBox='0 0 1366 320' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 44.9996C514.051 -15.1191 812.386 -13.2102 1366 44.9996V320H0V44.9996Z' fill='%23323D68'/%3E%3C/svg%3E%0A");
    background-size: 100%;
    background-repeat: no-repeat;
  }
  .userListWrapper {
    li {
      div {
        @apply flex items-center;
        span {
          &.verified {
            @apply bg-left bg-no-repeat pl-4 text-xs font-medium capitalize leading-4.5 text-green-dark;
            background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='10' height='7' viewBox='0 0 10 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fillRule='evenodd' clipRule='evenodd' d='M9.28033 0.21967C9.57322 0.512563 9.57322 0.987437 9.28033 1.28033L3.78033 6.78033C3.48744 7.07322 3.01256 7.07322 2.71967 6.78033L0.21967 4.28033C-0.0732233 3.98744 -0.0732233 3.51256 0.21967 3.21967C0.512563 2.92678 0.987437 2.92678 1.28033 3.21967L3.25 5.18934L8.21967 0.21967C8.51256 -0.0732233 8.98744 -0.0732233 9.28033 0.21967Z' fill='%23298529'/%3e%3c/svg%3e ");
          }
          &.unVerified {
            @apply whitespace-nowrap bg-left bg-no-repeat pl-4 text-xs font-medium capitalize leading-4.5 text-yellow-dark;
            background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fillRule='evenodd' clipRule='evenodd' d='M5.99999 1.60002C3.56994 1.60002 1.59999 3.56997 1.59999 6.00002C1.59999 8.43008 3.56994 10.4 5.99999 10.4C8.43005 10.4 10.4 8.43008 10.4 6.00002C10.4 3.56997 8.43005 1.60002 5.99999 1.60002ZM0.399994 6.00002C0.399994 2.90723 2.9072 0.400024 5.99999 0.400024C9.09279 0.400024 11.6 2.90723 11.6 6.00002C11.6 9.09282 9.09279 11.6 5.99999 11.6C2.9072 11.6 0.399994 9.09282 0.399994 6.00002Z' fill='%23A07808'/%3e%3cpath fillRule='evenodd' clipRule='evenodd' d='M5.99999 2.40002C6.33136 2.40002 6.59999 2.66865 6.59999 3.00002V5.6292L8.26832 6.46337C8.56471 6.61156 8.68484 6.97197 8.53665 7.26835C8.38846 7.56474 8.02805 7.68487 7.73167 7.53668L5.73167 6.53668C5.5284 6.43505 5.39999 6.22729 5.39999 6.00002V3.00002C5.39999 2.66865 5.66862 2.40002 5.99999 2.40002Z' fill='%23A07808'/%3e%3c/svg%3e ");
          }
          &.remove_billing {
            @apply whitespace-nowrap bg-left bg-no-repeat pl-4 text-xs font-medium capitalize leading-4.5 text-red-dark;
            background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fillRule='evenodd' clipRule='evenodd' d='M5.99999 1.60002C3.56994 1.60002 1.59999 3.56997 1.59999 6.00002C1.59999 8.43008 3.56994 10.4 5.99999 10.4C8.43005 10.4 10.4 8.43008 10.4 6.00002C10.4 3.56997 8.43005 1.60002 5.99999 1.60002ZM0.399994 6.00002C0.399994 2.90723 2.9072 0.400024 5.99999 0.400024C9.09279 0.400024 11.6 2.90723 11.6 6.00002C11.6 9.09282 9.09279 11.6 5.99999 11.6C2.9072 11.6 0.399994 9.09282 0.399994 6.00002Z' fill='%23A07808'/%3e%3cpath fillRule='evenodd' clipRule='evenodd' d='M5.99999 2.40002C6.33136 2.40002 6.59999 2.66865 6.59999 3.00002V5.6292L8.26832 6.46337C8.56471 6.61156 8.68484 6.97197 8.53665 7.26835C8.38846 7.56474 8.02805 7.68487 7.73167 7.53668L5.73167 6.53668C5.5284 6.43505 5.39999 6.22729 5.39999 6.00002V3.00002C5.39999 2.66865 5.66862 2.40002 5.99999 2.40002Z' fill='%23A07808'/%3e%3c/svg%3e ");
          }
        }
        &.addedBy {
          @apply flex items-center truncate;
          span + span {
            @apply relative ml-1.5 flex items-center truncate pl-2.5 text-gray-medium;
            &:before {
              content: '';
              @apply absolute left-0 float-left h-1 w-1 rounded-full bg-gray-light5;
            }
          }
        }
        &.lastLogin {
          @apply flex items-center;
          span + span {
            @apply relative ml-1.5 flex items-center pl-2.5;
            &:before {
              content: '';
              @apply absolute left-0 float-left h-1 w-1 rounded-full bg-gray-light5;
            }
          }
        }
      }
    }
  }
  .plans-details {
    ul {
      @apply mt-6 mb-6;
      li {
        @apply relative mb-3.5 max-w-fit pl-6.5 text-xs leading-[18px] md:mb-2;
        &:before {
          @apply absolute top-0.5 left-0 h-4.5 w-4.5 bg-no-repeat content-[''];
          background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 16.5C13.125 16.5 16.5 13.125 16.5 9C16.5 4.875 13.125 1.5 9 1.5C4.875 1.5 1.5 4.875 1.5 9C1.5 13.125 4.875 16.5 9 16.5Z' fill='%23298529'/%3E%3Cpath d='M6.75 9.37674L7.97589 10.5L11.25 7.5' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
          background-position: top -1px left 0px;
        }
      }
    }
  }
  .planSelector {
    @apply md:ml-auto md:mr-auto md:-mt-1;

    [class*='-control'] {
      @apply h-12 rounded-lg border-gray-medium md:h-11;
      @media (max-width: 991px) {
        min-width: 252px;
      }
    }
    [class*='-ValueContainer'] {
      @apply text-left;
    }
    [class*='-placeholder'] {
      @apply text-sm font-medium text-gray-dark;
    }
    [class*='-singleValue'] {
      @apply text-sm font-medium text-gray-dark;
    }
    [class*='-menu'] {
      @apply text-left text-sm font-medium text-gray-dark;
    }
    [class*='-indicatorSeparator'] {
      display: none;
    }
    [class*='-indicatorContainer'] {
      svg {
        @apply text-gray-medium;
      }
    }
  }
  .workspaceListCard {
    &:hover {
      a {
        @apply text-primary;
      }
    }
  }
  .channel-outline {
    &:hover {
      @apply outline-none outline-primary;
    }
  }
  .channel-outline-active {
    outline-style: auto;
    @apply rounded-lg shadow-4xl outline-2 outline-primary;
  }
  .custom-grid-columns {
    grid-template-columns: auto 220px 90px 90px 130px;
  }
  .search-results .common_dashboardWrapper__dfSP6 {
    @apply h-full overflow-hidden pb-0;
  }
  .tooltip-icon {
    svg {
      @apply h-3.5 w-3.5 text-gray-light5;
    }
  }
  .active-state {
    .heading,
    .button {
      @apply font-medium text-primary;
    }
  }
  .channel-cards {
    @media screen and (max-width: 992px) {
      &.activeChannel {
        @apply flex opacity-0;
        transform: translateY(10px);
        animation: slowSlideup 0.2s 0.2s forwards;
        @keyframes slowSlideup {
          100% {
            transform: translateY(0);
            opacity: 1;
          }
        }
      }
      &:not(.activeChannel) {
        @apply hidden;
      }
    }
  }
  .current-user {
    &:before {
      @apply absolute top-1.5 -left-2.5 h-1 w-1 rounded-full bg-gray-light5;
      content: '';
    }
  }
  .login-icon {
    @apply h-4 w-4 text-gray-light;
  }
  .remove-card {
    &:hover {
      .trashIcon {
        svg {
          [class*='trash-lid'] {
            transform: rotate(0) translate(0, 0);
          }
        }
      }
    }
    .trashIcon {
      svg {
        @apply h-5 w-5 text-gray-dark1;
        [class*='trash-lid'] {
          transform: rotate(-7deg) translate(-0.025rem, 3px);
          transition: 0.3s all;
        }
      }
    }
  }
  .add-user-select {
    @apply h-12;
    .hydra-dropdown__control {
      @apply h-full;
      border-radius: 8px !important;
      border-color: #4e5562 !important;
    }
    .hydra-dropdown__value-container {
      padding: 12px 16px;
    }
    .hydra-dropdown__placeholder {
      margin: 0;
      text-align: left;
      font-size: 14px;
      font-weight: 500;
      color: #9ca3af;
    }
    .hydra-dropdown__input-container {
      padding: 0;
      margin: 0;
    }
    .hydra-dropdown__control--is-focused {
      outline: 1px solid black !important;
      box-shadow: none;
    }
    .hydra-dropdown__single-value {
      text-align: left;
      margin: 0;
      @apply truncate text-sm font-medium leading-[1.375rem] text-gray-dark;
    }
    .hydra-dropdown__menu-list {
      text-align: left;
    }
    .hydra-dropdown__option--is-focused {
      background-color: #213c92 !important;
      color: #ffffff !important;
    }
    .hydra-dropdown__option.hydra-dropdown__option--is-selected.hydra-dropdown__option--is-selected {
      background-color: #fcfaf6;
      color: black;
    }
  }
  .suggestion {
    cursor: pointer;
    &:hover {
      background-color: #f9f9f9;
    }
    @apply border-b border-solid border-gray-medium;
    &:last-child {
      border: none;
      &:hover {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }
    &:first-child {
      &:hover {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }
    }
  }
}
.tagsHolder {
  @apply flex flex-wrap items-center pt-3;
  .tag {
    @apply flex items-center py-1 px-2 font-medium;
    margin: 3px;
    font-size: 13px;
    margin-right: 5px;
    border: 1px solid #959595;
    border-radius: 50px;
    background-color: #d3d3d3;
    white-space: nowrap;
    color: #1f1f1f;
  }

  .tag button {
    border: none;
    cursor: pointer;
    color: rgb(179, 8, 8);
    background: rgb(211, 211, 211);
    width: 18px;
    flex: 0 0 18px;
    height: 18px;
    padding: 0;
    display: FLEX;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-weight: 600;
    margin-left: 5px;
    line-height: 18px;
  }
}
.alertIcon {
  svg {
    height: 50px;
  }
}
.signUploader {
  @apply absolute left-0 top-0 z-10 h-full w-full bg-white/20 backdrop-blur-sm;
}
.pricingList {
  &:hover {
    @apply bg-gradient-to-r from-primaryMedium to-primaryDark py-7.5 px-6 text-white transition delay-700 duration-300 ease-in-out  md:w-full md:py-4 md:px-4;
    min-height: 478px;
    border-radius: 32px;
    @media screen and (max-width: 992px) {
      min-height: auto;
    }
    h2 {
      @apply text-lg leading-6 text-white md:text-xs;
      margin-bottom: 8px;
    }
    h3 {
      @apply border-white/5 pb-4 text-2.1xl leading-10 text-white md:text-base;
    }
    @apply w-72;
    transition: all 0.5s ease;
    transform: scale(1.1);

    h3,
    h2 {
      @apply text-white;
    }
    button {
      @apply bg-white text-primaryMedium;
    }
    ul {
      li {
        @apply max-w-fit pl-6.5 text-xs;
        line-height: 18px;
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M9 16.5C13.125 16.5 16.5 13.125 16.5 9C16.5 4.875 13.125 1.5 9 1.5C4.875 1.5 1.5 4.875 1.5 9C1.5 13.125 4.875 16.5 9 16.5Z' fill='white'/%3e%3cpath d='M6.75 9.37674L7.97589 10.5L11.25 7.5' stroke='%231877F2' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'/%3e%3c/svg%3e ");
        background-position: top -2px left 0px;
        background-position: top -1px left 0px;

        & + li {
          @apply mt-3.5;
        }
      }
      @media screen and (max-width: 992px) {
        li {
          font-size: 10px !important;
          line-height: 15px !important;
          max-width: 100% !important;

          & + li {
            @apply mt-2;
          }
        }
      }
    }
    @media screen and (max-width: 992px) {
      width: 100% !important;
      transform: none !important;
    }
  }
}
.user-management-buttons {
  @media screen and (max-width: 600px) {
    display: grid !important;
    gap: 10px;
    button {
      margin-left: 0 !important;
      width: 100%;
    }
  }
}
.additional-license-button {
  svg {
    width: 14px;
    height: 14px;
  }
}

.additional-license-modal-input {
  label {
    display: none;
  }
  input {
    height: 40px !important;
    padding: 5px 10px !important;
    border-radius: 6px !important;
    font-size: 14px !important;
    width: 60px !important;
    text-align: center;
  }
}

.license-upgrade-modal {
  .modal-content {
    ul {
      li {
        @apply bg-no-repeat p-0 pl-4.5 text-left text-sm font-normal leading-[15px];
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='15' height='16' viewBox='0 0 15 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M7.36161 14.2625C10.6845 14.2625 13.4033 11.5438 13.4033 8.22087C13.4033 4.89795 10.6845 2.1792 7.36161 2.1792C4.0387 2.1792 1.31995 4.89795 1.31995 8.22087C1.31995 11.5438 4.0387 14.2625 7.36161 14.2625Z' fill='%2327AE60'/%3e%3cpath d='M5.54907 8.52452L6.53659 9.42936L9.17407 7.0127' stroke='white' strokeWidth='1.61111' strokeLinecap='round' strokeLinejoin='round'/%3e%3c/svg%3e ");
        background-position: top 0 left 0px;

        & + li {
          @apply mt-3;
        }
      }
      @media screen and (max-width: 992px) {
        padding-bottom: 24px;
        li {
          @apply max-w-full;

          & + li {
            @apply mt-2;
          }
        }
      }
    }
  }
}
.userManagementList {
  border: 0.063rem solid #e4e4e4;
  border-radius: 0.625rem;
  padding: 1rem 1rem 0.875rem 1rem;
  background-color: #ffffff;
  table {
    border: none;
    overflow: hidden;
  }
}
