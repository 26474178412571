@use '../../../assets/css/index.scss' as *;

@include cardStyles;
.channelWrap {
  @extend %flexShrink-0;
  width: 18.625rem;
  @extend %flex-f-column;
  @include mediaQuery(lg) {
    width: 100%;
    &.activeChannel {
      transform: translateY(10px);
      opacity: 0;
      display: flex;
      animation: slowSlideup $hydra-transition-speed-3 $hydra-transition-speed-3 forwards;
      @keyframes slowSlideup {
        100% {
          transform: translateY(0);
          opacity: 1;
        }
      }
    }
    &:not(.activeChannel) {
      display: none;
    }
  }
}
.channelCard {
  padding: 0;
  @extend %flex-f-column;
  height: 100%;
  @include mediaQuery(lg) {
    padding: 0;
    border: 0;
    background-color: transparent;
  }
  .channelCardHeader {
    padding: $hydra-spacing-16;
    border-bottom: $hydra-dim-01 solid $borderShade5;
    @extend %flex;
    @include column-gap($hydra-spacing-08);
    @include mediaQuery(lg) {
      display: none;
    }
    .logo {
      @extend %flexShrink-0;
      img {
        width: 1.875rem;
        border-radius: $hydra-radius-6;
        height: 1.875rem;
      }
    }
    .channelTitleWrapper {
      @extend %flex-f-column;
      min-width: 0;
      .channelTitle {
        font-size: $hydra-text-xs;
        font-weight: $hydra-font-semibold;
        line-height: 1.5;
        color: $textColor8;
        @extend %truncate;
      }
      .channelPageCount {
        font-size: $hydra-text-x;
        font-weight: $hydra-font-medium;
        line-height: 1.5;
        color: $textColor9;
      }
    }
    .externalLink {
      color: $primary100;
      margin-left: auto;
      transition: $hydra-transition-speed-3 all;
      a {
        &:hover {
          @extend %arrowLineExternalLine;
        }
      }
      svg {
        width: $hydra-dim-14;
        height: $hydra-dim-14;
        @include mediaQuery('lg') {
          color: $gray700;
        }
      }
    }
  }
  .channelCardBody {
    padding: $hydra-spacing-16;
    @extend %flex-f-column;
    @include row-gap($hydra-spacing-16);
    overflow: auto;
    height: 100%;
    @include mediaQuery(lg) {
      padding: 0;
      border: 0;
      background-color: $white;
      overflow: unset;
    }
  }
}
.floatAction {
  position: sticky;
  bottom: (-($hydra-spacing-16));
  border-top: $hydra-dim-01 solid $borderShade4;
  margin: 0 (-($hydra-spacing-16)) (-($hydra-spacing-16));
  @extend %flex-a-j-center;
  padding: $hydra-spacing-16;
  z-index: $hydra-zIndex-1;
  @include grid-gap($hydra-spacing-08);
  background-color: $white;
  min-height: 4rem;
  border-radius: 0 0 $hydra-radius-8 $hydra-radius-8;
  margin-top: auto;
  @include mediaQuery(lg) {
    bottom: 0;
    padding: $hydra-spacing-24 $hydra-spacing-20 $hydra-spacing-32;
  }
  button {
    width: 100%;
    min-width: 8.25rem;
    @extend %flex-a-j-center;
    @include mediaQuery(lg) {
      min-height: 2.75rem;
    }
    span {
      font-weight: $hydra-font-semibold;
    }
  }
}
.pageWrapper {
  @extend %flex-f-column;
  transition: $hydra-transition-speed-3 all;
  cursor: pointer;
  border-radius: $hydra-radius-6;
  &:hover {
    outline: $hydra-dim-01 solid $primary100;
    // .pageHeader {
    //   border-top-color: $primary100;
    //   border-left-color: $primary100;
    //   border-right-color: $primary100;
    //   border-bottom-color: $primary100;
    // }
    .pageBody {
      border-bottom-color: $primary100;
      border-left-color: $primary100;
      border-right-color: $primary100;
      
    }
  }
  &.edit{
   .formControlWrapper{
    order: 1;
    background: white;
    border: 0.063rem solid #DDDDDD;
    border-top:0;
    border-radius: 0 0 0.375rem 0.375rem;
    padding:5px 10px 10px;
    label{
      color: #576A80;
      font-size: 12px;
    }
    & + .pageHeader{
      border-radius:0.375rem 0.375rem 0 0 ;
    }
   }
  }
  .pageHeader {
    border-radius: $hydra-radius-6;
    background-color: $primary400;
    border: $hydra-dim-01 solid $borderShade4;
    @extend %flex-a-item-center;
    padding: $hydra-spacing-08 $hydra-spacing-16;
    @include column-gap($hydra-spacing-08);
    .pageLogo {
      width: $hydra-spacing-28;
      height: $hydra-spacing-28;
      border-radius: $hydra-radius-6;
      @extend %flexShrink-0;
      border: $hydra-dim-01 solid $borderShade4;
      @extend %flex-a-j-center;
      overflow: hidden;

      img {
        width: 100%;
        object-fit: contain;
      }
    }
    .title {
      color: $textColor8;
      font-size: $hydra-text-xs;
      line-height: 1.5;
      font-weight: $hydra-font-semibold;
      @extend %truncate;
    }
    .pageChecker {
      margin-left: auto;
    }
  }
  .pageBody {
    border: $hydra-dim-01 solid $borderShade4;
    border-radius: 0 0 $hydra-radius-6 $hydra-radius-6;
    border-top: 0;
    padding: $hydra-spacing-16;
    @extend %flex-f-column;
    @extend %formControlStyles;
    .pageRow {
      @extend %flex;
      position: relative;
      flex-grow: 1;
      @include column-gap(2rem);
      padding-bottom: $hydra-spacing-16;
      &:last-child {
        padding-bottom: 0;
      }
      @include mediaQuery(lg) {
        ~ .formControlWrapper {
          [class*='hydra-dropdown__menu'] {
            visibility: hidden; // hide if not needing the slide panel
          }
        }
      }
      + .pageRow {
        padding-top: $hydra-spacing-16;
        &::before {
          content: '';
          background-color: $borderShade5;
          position: absolute;
          top: 0;
          right: 0;
          height: $hydra-dim-01;
          width: 88%;
        }
      }
      .pageProperty {
        @extend %flex;
        @include column-gap($hydra-spacing-08);
        flex-grow: 1;
        .typeIcon {
          margin-top: $hydra-spacing-04;
          svg {
            color: $textColor9;
            width: $hydra-dim-16;
            height: $hydra-dim-16;
          }
        }
        .typeDetails {
          .typeDetailsTitle {
            color: $textColor9;
            font-weight: $hydra-font-regular;
            font-size: $hydra-text-xs;
            line-height: 1.5;
          }
          .typeDetailsStatus {
            color: $textColor8;
            font-weight: $hydra-font-medium;
            font-size: $hydra-text-xs;
            line-height: 1.5;
            span {
              &:not(:last-child) {
                &:after {
                  content: ' & ';
                }
              }
            }
          }
        }
      }
    }
  }
}
.removedItem {
  width: 100%;
  @extend %flex-a-item-start;
  border: $hydra-dim-01 dashed $textRedColor;
  background-color: $restrictedBg;
  padding: $hydra-spacing-16;
  border-radius: $hydra-radius-8;
  @include grid-gap($hydra-spacing-08);
  &:hover {
    .trashIcon {
      svg {
        [class*='trash-lid'] {
          transform: rotate(0) translate(0, 0);
        }
      }
    }
  }
  .trashIcon {
    svg {
      width: $hydra-dim-20;
      height: $hydra-dim-20;
      color: $gray500;
      [class*='trash-lid'] {
        transform: rotate(-7deg) translate(-0.025rem, $hydra-dim-03);
        transition: $hydra-transition-speed-3 all;
      }
    }
  }
  .trashMessage {
    color: $textColor9;
    font-size: $hydra-text-xs;
    line-height: 1.5;
    font-weight: $hydra-font-medium;
  }
  .undoBtn {
    line-height: 1.5;
    margin-left: auto;
  }
}
