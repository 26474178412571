@use "../../assets/css/index.scss" as *;

@include dropdownCustomStyles;
.dropdownReorder {
  padding: $hydra-spacing-16 $hydra-spacing-20 0;
  border-top: $hydra-dim-01 solid $borderShade4;
  .customReorderTitle {
    margin-bottom: $hydra-spacing-08;
    @include mediaQuery(lg) {
      margin-bottom: $hydra-spacing-16;
    }
    h3 {
      font-size: $hydra-text-xs;
      line-height: 1.5;
      font-weight: $hydra-font-regular;
      color: $textColor9;
      margin-bottom: $hydra-spacing-04;
      @include mediaQuery(lg) {
        font-size: $hydra-text-sm;
      }
    }
    p {
      font-size: $hydra-text-x;
      line-height: 1.5;
      font-weight: $hydra-font-regular;
      color: $textColor9;
      @include mediaQuery(lg) {
        font-size: $hydra-text-xs;
      }
    }
  }
}

.dragAndDrop {
  @extend %flex-f-column;
  margin: 0 (-($hydra-spacing-20));
  overflow: auto;
  overscroll-behavior: contain;
  // max-height: calc(100vh - 573px);
  max-height: 150px;
  ::-webkit-scrollbar {
    @apply w-1;
  }
  &::-webkit-scrollbar {
    @apply w-1;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    @apply bg-transparent;
  }
  &::-webkit-scrollbar-track {
    @apply bg-transparent;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    @apply rounded-[30px] bg-gray-light5;
  }
  &::-webkit-scrollbar-thumb {
    @apply rounded-[30px] bg-gray-light5;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  li {
    @extend %flex-a-item-center;
    width: 100%;
    @include column-gap($hydra-spacing-08);
    padding: $hydra-spacing-10 $hydra-spacing-20;
    transition: $hydra-transition-speed-3 all;
    @include mediaQuery(lg) {
      padding: $hydra-spacing-08 $hydra-spacing-20;
    }
    &:hover {
      background-color: $primary400;
    }
  }
  .dragHandle {
    cursor: grab;
    svg {
      width: $hydra-dim-20;
      height: $hydra-dim-20;
      color: $gray900;
    }
  }
  .logo {
    width: $hydra-spacing-22;
    height: $hydra-spacing-22;
    @extend %flexShrink-0;
    img,
    svg {
      width: $hydra-spacing-22;
      object-fit: contain;
      height: $hydra-spacing-22;
      border-radius: $hydra-radius-8;
    }
  }
  .title {
    font-size: $hydra-text-sm;
    line-height: 1.5;
    text-transform: capitalize;
    color: $textColor8;
    font-weight: $hydra-font-regular;
    @extend %truncate;
  }
  .showStatus {
    margin-left: auto;
  }
}
