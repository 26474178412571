.container {
    display: flex;
    overflow: scroll;
    width: 100%;
    max-width: 100%;
    padding-left: 14px;
    border: 1px grey solid;
    border-radius: 5px;
    color: black
  }
  .container input {
    width: 100%;
    min-width: 50%;
    border: none;
    border-radius: 5px;
    padding: 14px;
    padding-left: 14px;
  }
  .tag {
    display: flex;
    align-items: center;
    margin: 3px;
    font-size: 13px;
    margin-right: 5px;
    padding: 0 10px;
    padding-right: 5px;
    border: 1px solid blue;
    border-radius: 5px;
    background-color: blue;
    white-space: nowrap;
    color: white;
  }
  .tag button {
    border: none;
    cursor: pointer;
    color: red;
    background: hsla(0, 0%, 100%, 1);
    width: 18px;
    flex: 0 0 18px;
    height: 18px;
    padding: 0;
    display: FLEX;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-weight: 600;
    margin-left: 5px;
  }
  