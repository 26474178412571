@use '../../assets/css/index.scss' as *;

.coreConsole {
  background-color: $primary300;
  padding: 0;
  width: 100%;
  overflow: auto;
  @extend %flex-f-column;
  @include column-gap($hydra-spacing-12);
  height: 100%;
  @include mediaQuery('lg') {
    padding: 0;
    background-color: $white;
    flex-direction: column;
    overflow: unset;
  }
  .broadcastHeader {
    background-color: $white;
    padding: $hydra-spacing-16;
    @extend %flex-a-item-center;
    padding-bottom: $hydra-spacing-02;
    @include mediaQuery(lg) {
      padding: $hydra-spacing-16 $hydra-spacing-20;
    }
    h3 {
      font-weight: $hydra-font-semibold;
      font-size: $hydra-text-sm;
      color: $textColor8;
    }
  }
  .broadcastMessages {
    @extend %flex-f-column;
    overflow: auto;
    height: 100%;
    @include mediaQuery(lg) {
      height: calc(100vh - 228px);
    }
  }
  .broadcastMessagesList {
    background-color: $white;
    border-bottom: $hydra-spacing-01 solid $borderShade4;
    padding-bottom: $hydra-spacing-06;
    .broadcastType {
      position: relative;
      @include mediaQuery(lg) {
        display: flex;
        align-items: center;
        .tabsetTab {
          width: 100%;
          font-size: $hydra-text-sm;
          &:before {
            max-width: 100%;
          }
        }
      }
    }
  }
  .broadcastMessagesWrapper {
    @extend %flex;
    overflow: auto;
    height: 100%;
    @include mediaQuery(lg) {
      overflow: hidden;
    }
    .msgList {
      max-width: 16.375rem;
      overflow: auto;
      background-color: $white;
      border-right: $hydra-spacing-01 solid $borderShade4;
      width: 100%;
      text-align: left;
      @include mediaQuery(lg) {
        max-width: 100%;
        @include flex-shrink(0);
      }
      .noRecords {
        height: 100%;
        border: 0;
      }
      ul {
        @extend %flex-f-column;
        li {
          .messageItem {
            padding: $hydra-spacing-16;
            width: 100%;
            @extend %flex-a-item-start;
            @include column-gap($hydra-spacing-08);
            transition: $hydra-transition-speed-3 all;
            &:hover {
              background-color: $primary300;
            }
            &.active {
              background-color: $primary600;
            }
            .imageIcon {
              @extend %profileIcon;
            }
            .msgDetails {
              text-align: left;
              .userName {
                font-size: $hydra-text-xs;
                color: $textColor8;
                line-height: 1.5;
                font-weight: $hydra-font-medium;
                @extend %truncate;
                -webkit-line-clamp: 1;
              }
              .subject {
                font-size: $hydra-text-xxs;
                color: $textColor9;
                line-height: 1.5;
                .subjectWorkspace {
                  font-weight: $hydra-font-medium;
                  margin-right: $hydra-spacing-08;
                }
                .subjectText {
                  font-weight: $hydra-font-regular;
                }
              }
              .date {
                font-size: $hydra-text-x;
                line-height: 1.5;
                font-weight: $hydra-font-regular;
                margin-top: $hydra-spacing-04;
                color: $textColor9;
              }
              @include mediaQuery(lg) {
                width: 100%;
                display: grid;
                @include grid-temp-columns(1fr 3.25rem);
                .userName {
                  @include grid-area(1, 2, 1, 2);
                }
                .subject {
                  @include grid-area(1, 2, 2, 3);
                  margin-top: $hydra-spacing-02;
                }
                .date {
                  @include grid-area(2, 3, 1, 3);
                  text-align: right;
                  margin-top: 0;
                }
              }
            }
          }
          + li {
            position: relative;
            &:before {
              content: '';
              background-color: $borderShade5;
              height: $hydra-dim-01;
              width: 100%; 
              max-width: 12rem;
              position: absolute;
              right: $hydra-dim-14;
              @include mediaQuery(lg) {
                max-width: 100%;
                right: 0;
              }
            }
          }
        }
      }
    }
    .broadcastDetails {
      width: 100%;
      padding: $hydra-spacing-18;
      overflow: auto;
      @include mediaQuery(lg) {
        @include flex-shrink(0);
        width: 100%;
        transform: translateX(100%);
        transition: $hydra-transition-speed-3 all;
        position: fixed;
        top: 4.4375rem;
        left: 0;
        z-index: $hydra-zIndex-2;
        height: calc(100% - 72px);
        border-radius: 0;
        padding: 0;
        &.screenflowActive {
          transform: translateX(0);
        }
      }
      .card {
        height: 100%;
        overflow: auto;
        @include mediaQuery(lg) {
          border-radius: 0;
          padding-top: 0;
        }
      }
    }
  }
  .mdDate {
    margin-left: auto;
    color: $gray500;
    font-size: $hydra-text-xs;
    @include mediaQuery(lg) {
      margin-top: $hydra-spacing-08;
    }
  }
  .messageDetailed {
    @extend %flex-f-column;
    .mdRow {
      @extend %flex;
      @include column-gap($hydra-spacing-08);
      @include mediaQuery(lg) {
        &:first-child {
          border-bottom: $hydra-dim-01 solid $borderShade4;
          padding-bottom: $hydra-spacing-08;
          margin-bottom: $hydra-spacing-16;
        }
      }
    }
    .mdDetailsLeft {
      @extend %flex-f-column;
    }
    .mdImageIcon {
      @extend %profileIcon;
    }
    .mdDetails {
      width: 100%;
      @extend %flex;
      padding-bottom: $hydra-spacing-24;
      border-bottom: $hydra-dim-01 solid $borderShade4;
      @include mediaQuery(lg) {
        flex-direction: column;
        padding: 0;
        border-bottom: 0;
      }
      .mdDetailsWrapper {
        font-size: $hydra-text-sm;
        line-height: 1.5;
        font-weight: $hydra-font-medium;
        color: $textColor8;
        .mdDetailsName {
          font-weight: $hydra-font-medium;
          display: inline;
          margin-right: $hydra-spacing-15;
          word-break: break-word;
        }
        .mdDetailsId {
          font-weight: $hydra-font-regular;
          font-size: $hydra-text-xs;
          display: inline;
          word-break: break-all;
          position: relative;
          &:before {
            content: '';
            background-color: $gray800;
            height: $hydra-dim-04;
            border-radius: $hydra-radius-full;
            width: $hydra-dim-04;
            position: absolute;
            top: $hydra-spacing-07;
            left: (-($hydra-spacing-09));
            @include mediaQuery(lg) {
              top: $hydra-spacing-09;
            }
          }
          @include mediaQuery(lg) {
            font-size: $hydra-text-sm;
          }
        }
      }
      .mdDetailsSubject {
        font-size: $hydra-text-xs;
        line-height: 1.5;
        color: $textColor9;
        margin-top: $hydra-spacing-02;
        .mdDetailsWorkspace {
          font-weight: $hydra-font-medium;
          margin-right: $hydra-spacing-08;
          display: inline;
        }
        .mdDetailsWSSubject {
          font-weight: $hydra-font-regular;
          display: inline;
        }
      }
      .mdDetailsCC {
        @extend %flex-a-item-start;
        font-size: $hydra-text-xs;
        line-height: 1.5;
        color: $textColor9;
        margin-top: $hydra-spacing-02;
        @include column-gap($hydra-spacing-08);
        .mdDetailsCCTitle {
          font-weight: $hydra-font-medium;
        }
        .moreItems {
          color: $primary100;
          font-weight: $hydra-font-medium;
        }
        .mdDetailsWSSubject {
          @extend %flex-a-item-start;
          @extend %flex-wrap;
          @include grid-gap($hydra-spacing-02);
          .mailID {
            &:last-child {
              content: '';
            }
            &:after {
              content: ',';
            }
          }
        }
      }
    }
  }
  .mdData {
    padding-top: $hydra-spacing-24;
    font-size: $hydra-text-sm;
    margin-left: 2.25rem;
    img {
      border-radius: $hydra-radius-4;
    }
    @include mediaQuery(lg) {
      padding-top: 0;
      margin-left: 0;
    }
  }
  .newBroadcast {
    margin-left: auto;
    .viewAll {
      @extend %flex;
      @include column-gap($hydra-spacing-08);
      margin-left: auto;
    }
    @include mediaQuery(lg) {
      position: fixed;
      background-color: $white;
      bottom: 0;
      left: 0;
      z-index: $hydra-zIndex-1;
      border: 1px solid $borderShade4;
      box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.09);
      padding: $hydra-spacing-24 $hydra-spacing-20 $hydra-spacing-32;
      width: 100%;

      .viewAll {
        width: 100%;
        font-size: $hydra-text-sm;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 2.75rem;
      }
    }
  }
}

.modalContent {
  @extend %flex-f-column;
  height: 100%;
  .formController {
    @extend %flex-f-column;
    @include row-gap($hydra-spacing-24);
    height: 100%;
    .editor {
      @extend %flex-f-column;
      height: 100%;
    }
  }
  .broadcastWrapper {
    @extend %flex-wrap;
    @extend %flex;
    @include column-gap($hydra-spacing-40);
    @include mediaQuery(lg) {
      flex-wrap: nowrap;
      @include column-gap($hydra-spacing-08);
    }
  }
}
.modalFooter {
  @extend %flex;
  margin-top: $hydra-spacing-24;
  .modalFtRight {
    @extend %flex;
    margin-left: auto;
    @include column-gap($hydra-spacing-08);
    button {
      @extend %flex-a-j-center;
      min-width: 5.875rem;
      min-height: 2rem;
    }
  }
}

.messagingWrapper {
  @include mediaQuery(lg) {
    padding: 0;
  }
}
.navigationScreen {
  padding: $hydra-spacing-16 0;
  z-index: $hydra-zIndex-1;
  position: sticky;
  background: $white;
  top: 0;
  .navBtn {
    @extend %flex-a-item-center;
    @include column-gap($hydra-spacing-08);
    svg {
      color: $gray500;
      width: 1.5rem;
      height: 1.5rem;
    }
    span {
      font-weight: $hydra-font-semibold;
      font-size: $hydra-text-base;
      color: $textColor8;
      line-height: 1.5;
    }
  }
}
@include cardStyles;
@include emptyCard;
@include tabset;
@include mediaQuery(lg) {
  .dvOnly {
    display: none;
  }
}

@include mediaQuery(lg, min-width) {
  .mvOnly {
    display: none;
  }
}
