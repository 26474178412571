@use "../../assets/css/index.scss" as *;

.channelWrapper {
  width: 15.125rem;
  @extend %flexShrink-0;
  background-color: $white;
  overflow: auto;
  height: 100%;
  border-right: $hydra-dim-01 solid $borderShade1;
  padding: $hydra-spacing-24 $hydra-spacing-08 $hydra-spacing-24
    $hydra-spacing-16;
  .headerWrapper {
    @extend %flex-a-item-center;
    @include mediaQuery("lg") {
      margin-bottom: $hydra-spacing-12;
    }
    .viewAll {
      display: none;
      font-size: $hydra-text-xs;
      margin-left: auto;
      @include mediaQuery("lg") {
        display: block;
      }
    }
    
  }
  @include mediaQuery("xl") {
    height: auto;
    width: 100%;
    padding: $hydra-spacing-12 $hydra-spacing-08 $hydra-spacing-12;
  }
  @include mediaQuery("lg") {
    padding: 0;
    border: 0;
    overflow: visible;
  }
}
.channelTitle {
  font-weight: $hydra-font-medium;
  font-size: $hydra-text-xs;
  line-height: 1.5;
  text-transform: capitalize;
  color: $gray500;
  @include mediaQuery("lg") {
    font-size: $hydra-text-sm;
    color: $black;
  }
}
.channelListWrapper {
  @include mediaQuery("xl") {
    flex-direction: row;
    display: flex;
  }
  @include mediaQuery("lg") {
    display: grid;
    @include grid-temp-columns(repeat(auto-fit, minmax(5rem, 1fr)));
    @include grid-gap($hydra-spacing-08);
    padding-bottom: $hydra-spacing-24;
  }
  li {
    .channelItem {
      padding: $hydra-spacing-08;
      @extend %flex-a-item-center;
      @include column-gap($hydra-spacing-08);
      transition: $hydra-transition-speed-3 all;
      cursor: pointer;
      border-radius: $hydra-radius-8;
      width: 100%;
      @include mediaQuery("lg") {
        flex-direction: column;
        justify-content: center;
        border: $hydra-dim-01 solid $borderShade2;
        height: 6.875rem;
        padding:$hydra-spacing-08 0;
      }
      .imgIcon {
        width: 1.5rem;
        height: 1.5rem;
        border-radius: $hydra-radius-full;
        object-fit: contain;
        @extend %flexShrink-0;
        @include mediaQuery("lg") {
          margin-bottom: $hydra-spacing-08;
        }
      }
      .title {
        font-weight: $hydra-font-regular;
        font-size: $hydra-text-xs;
        line-height: 1.5;
        text-transform: capitalize;
        color: $black;
        @extend %truncate;
        @include mediaQuery("lg") {
          white-space: unset;
          overflow: unset;
          text-align: center;
          @include lineClamp;
          -webkit-line-clamp: 2;
          margin-bottom: auto;
        }
      }
      &:hover {
        background-color: $primary200;
        .action {
          .addBtn {
            svg {
              color: $primary100;
            }
          }
        }
      }
      .action {
        margin-left: auto;
        margin-top: -$hydra-spacing-02;
        @extend %flex;
        @include mediaQuery("lg") {
          margin-top: $hydra-spacing-08;
          margin-left: auto;
          margin-right: auto;
        }
        > div {
          display: flex !important; //due to inline css
        }
        .addBtn {
          width: $hydra-dim-15;
          height: $hydra-dim-15;
          @extend %flexShrink-0;
          @include mediaQuery('lg'){
            width: $hydra-dim-18;
            height: $hydra-dim-18;
          }
          svg {
            width: 100%;
            height: 100%;
            color: $gray500;
            @include mediaQuery('lg'){
              color: $gray700;
            }
          }
        }
      }
    }
  }
}
