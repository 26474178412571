@use "../../assets/css/index.scss" as *;

.customModalWrapper {
  .customModalContent {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: $hydra-zIndex-modal-panel;
    // max-height: 36.375rem;

    max-height: calc(100vh - 6.875rem);
    // height: 100%;
    background-color: $white;
    overflow: auto;
    margin: 1.75rem auto;
    border-radius: $hydra-radius-8;
    @extend %flex-f-column;
    height: 100%;
    opacity: 0;
    animation: opacityFade $hydra-transition-speed-3 ease-in-out forwards;
  }
  &[class*="lg"] {
    .customModalContent {
      max-width: 46.625rem;
      width: 100%;
      height: auto;
    }
  }
  &[class*="pageLikeModalMob"] {
    @include mediaQuery(lg) {
      .customModalContent {
        top: 4.4375rem;
        left: 0;
        margin: 0;
        border-radius: 0;
        max-height: 100%;
        height: calc(100% - 4.4375rem);
        animation: slideup $hydra-transition-speed-3 ease-in-out forwards;
        transform: translate(0, 100%);
        max-width: 100%;
        z-index: $hydra-zIndex-slide-panel;
      }
      .customModalBody {
        overflow: auto;
        padding: 0;
        background-color: $primary300;
        div[class*="modalContent"] {
          padding: $hydra-spacing-16 $hydra-spacing-20;
          overflow: auto;
        }
        div[class*="messaging_editor"] {
          min-height: 21.875rem;
          margin-bottom: $hydra-spacing-16;
        }
      }
      div[class*="modalFooter"] {
        position: sticky;
        bottom: 0;
        z-index: 1;
        background-color: $primary300;
        padding: $hydra-spacing-16 $hydra-spacing-20;
        div[class*="modalFtRight"] {
          width: 100%;
          button {
            width: 100%;
            min-height: 2.75rem;
            font-size: $hydra-text-sm;
          }
        }
      }
      ~ .backdrop {
        display: none;
      }
      .customModalHeader {
        padding: $hydra-spacing-16 $hydra-spacing-20;
        border-bottom: $hydra-dim-01 solid $borderShade4;
        .btnClose {
          display: none;
        }
      }
    }
  }
  .customModalHeader {
    padding: $hydra-spacing-24;
    @extend %flex-a-item-center;
    h4 {
      font-size: $hydra-text-base;
      color: $textColor8;
      line-height: 1.5;
      font-weight: $hydra-font-semibold;
    }
    .btnClose {
      margin-left: auto;
      svg {
        width: $hydra-dim-20;
        height: $hydra-dim-20;
        color: $gray500;
      }
    }
  }
  .customModalBody {
    @extend %flex-f-column;
    height: 100%;
    padding: 0 $hydra-spacing-24 $hydra-spacing-24;
  }
}

.backdrop {
  z-index: $hydra-zIndex-modal-backdrop;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: $backdrop;
  opacity: 0;
  animation: opacityFade $hydra-transition-speed-3 ease-in-out forwards;
}

@keyframes opacityFade {
  100% {
    opacity: 1;
  }
}

@keyframes slideup {
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}
