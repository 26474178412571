@use "./variables.scss" as *;
@use "sass:map";
// mixins , extends , and other scss features ONLY
%flex {
  display: flex;
}

%flex-column {
  flex-direction: column;
}

%flex-a-item-center {
  display: flex;
  align-items: center;
}

%flex-j-center {
  display: flex;
  justify-content: center;
}

%flex-a-item-start {
  display: flex;
  align-items: flex-start;
}

%justify-between {
  justify-content: space-between;
}

%flex-f-column {
  display: flex;
  flex-direction: column;
}

%flex-a-end {
  align-items: flex-end;
}

%a-i-center {
  align-items: center;
}

%a-i-start {
  align-items: flex-start;
}

%flex-j-end {
  justify-content: flex-end;
}

%flex-j-start {
  justify-content: flex-start;
}

%flex-f-column-a-item-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

%flex-a-j-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

%flex-wrap {
  flex-wrap: wrap;
}

%flex-nowrap {
  flex-wrap: nowrap;
}

%flex-inline {
  display: inline-flex;
}

%truncate {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
@mixin truncate {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
%lineClamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
@mixin lineClamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
%flex-height-100-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
%flexShrink-0 {
  flex-shrink: 0;
}
%visuallyHidden {
  position: absolute;
  opacity: 0;
  top: 0;
  pointer-events: none;
  left: 0;
}
@mixin flex-shrink($value) {
  flex-shrink: $value;
}

//grid
%grid {
  display: -ms-grid;
  display: grid;
}

@mixin grid-temp-columns($value) {
  -ms-grid-columns: $value;
  grid-template-columns: $value;
}

@mixin grid-temp-row($value) {
  -ms-grid-row: $value;
  grid-template-rows: $value;
}

@mixin grid-columns($value) {
  -ms-grid-column: $value;
  grid-column: $value;
}

@mixin column-gap($value) {
  -webkit-column-gap: $value;
  -moz-column-gap: $value;
  column-gap: $value;
}

@mixin row-gap($value) {
  -webkit-row-gap: $value;
  -moz-row-gap: $value;
  row-gap: $value;
}
@mixin grid-gap($value) {
  -webkit-grid-gap: $value;
  -moz-grid-gap: $value;
  grid-gap: $value;
}
@mixin grid-area($col-start, $col-end, $row-start, $row-end) {
  -ms-grid-column: $col-start;
  -ms-grid-column-span: $col-end - $col-start;
  -ms-grid-row: $row-start;
  -ms-grid-row-span: $row-end - $row-start;
  grid-column: #{$col-start}/#{$col-end};
  grid-row: #{$row-start}/#{$row-end};
}
//media query
$mediaBreakpoints: (
  "xxxl": "87.5rem",
  //1400px
  "xxl": "83.5rem",
  //1,336px
  "xl-sm": "75.5rem",
  "xl": "75rem",
  //1,200px
  "lg": "62rem",
  //992px
  "md": "48rem",
  //768px
  "sm": "36rem",
  //576px
  "xsm": "26.25rem",
  "xs": "21.875rem",
  "x": "0",
);
@mixin mediaQuery($size, $mediaType: "max-width") {
  $breakpoint: map.get($mediaBreakpoints, $size);
  @media ($mediaType: $breakpoint) {
    @content;
  }
}

@mixin MobileViewWebViewSwitcher {
  &.webOnly {
    @include mediaQuery("lg") {
      display: none;
    }
  }
  &.mvOnly {
    @include mediaQuery("lg", "min-width") {
      display: none;
    }
  }
}

///input box search
.formControl {
  position: relative;
  @extend %flex-f-column;
  label {
    font-size: $hydra-text-xs;
    color: $textColor8;
    font-weight: $hydra-font-regular;
    margin-bottom: $hydra-spacing-06;
  }
  &:has(.formControlIconClear) {
    .formInput {
      padding-right: $hydra-spacing-40;
    }
  }
  .helptext {
    font-size: $hydra-text-x;
    color: $textColor9;
    margin: $hydra-spacing-03 0 0;
    &.error {
      color: $restrictedColor;
    }
  }
  .formControlIconPrefix {
    position: absolute;
    left: $hydra-spacing-17;
    top: $hydra-spacing-10;
    + .formInput {
      padding: $hydra-spacing-12 $hydra-spacing-16 $hydra-spacing-12
        $hydra-spacing-40;
    }
    svg {
      width: $hydra-dim-16;
      height: $hydra-dim-16;
      color: $gray500;
    }
  }
  .formControlIconClear {
    position: absolute;
    right: $hydra-spacing-17;
    top: $hydra-spacing-10;
    &:hover svg {
      color: $black;
    }
    svg {
      width: $hydra-dim-16;
      height: $hydra-dim-16;
      color: $gray500;
    }
  }
  .formInput {
    padding: $hydra-spacing-12 $hydra-spacing-12;
    border-radius: $hydra-radius-8;
    border: $hydra-dim-01 solid $gray600;
    background-color: $white200;
    font-size: $hydra-text-xs;
    line-height: 1.5;
    color: $black;
    font-weight: $hydra-font-regular;
    transition: $hydra-transition-speed-3 all;
    &.error {
      border-color: $restrictedColor;
    }
    &::placeholder {
      color: $gray500;
    }
    &:disabled {
      opacity: 0.4;
      pointer-events: none;
    }
    &:focus {
      outline-color: $primary100;
    }
    @include mediaQuery(lg) {
      background-color: $white;
    }
  }
}
%alertComponent {
  border-width: $hydra-dim-01;
  border-style: solid;
  padding: $hydra-spacing-11 $hydra-spacing-16;
  @extend %flex-a-item-start;
  @include column-gap($hydra-spacing-08);
  border-radius: $hydra-radius-8;
  filter: drop-shadow(
    0 $hydra-spacing-02 $hydra-spacing-04 rgba(0, 0, 0, 0.05)
  );
  .alertIcon {
    margin-top: $hydra-spacing-01;
    svg {
      width: $hydra-dim-20;
      height: $hydra-dim-20;
    }
  }
  .alertTitle {
    font-size: $hydra-text-xs;
    line-height: 2;
    font-weight: $hydra-font-regular;
    color: $textColor4;
  }
  .viewAll {
    margin-top: $hydra-spacing-06;
    margin-left: auto;
    font-size: $hydra-text-xs;
    line-height: 1.2;
    color: $primary100;
    font-weight: $hydra-font-medium;
  }
  &.success {
    background-color: $successBg;
    border-color: $successBorder;
    .alertIcon {
      svg {
        color: $success;
      }
    }
  }
}
%dropdownPrefixedPositions {
  &.bottom {
    animation: growDown $hydra-transition-speed-3 ease-in-out forwards;
    transform-origin: top center;
    top: calc(100% + $hydra-spacing-08);
  }
  &.top {
    animation: growUp $hydra-transition-speed-3 ease-in-out forwards;
    transform-origin: bottom center;
    bottom: calc(100% + $hydra-spacing-08);
  }
  @keyframes opacityFade {
    100% {
      opacity: 1;
      background: $backdrop;
    }
  }
  @keyframes slideUp {
    100% {
      transform: translateY(0);
    }
  }
  @keyframes growDown {
    0% {
      transform: scaleY(0);
    }
    80% {
      transform: scaleY(1.1);
      opacity: 0.65;
    }
    100% {
      transform: scaleY(1);
      opacity: 1;
    }
  }
  @keyframes growUp {
    0% {
      transform: scaleY(0);
    }
    80% {
      transform: scaleY(1.1);
      opacity: 0.65;
    }
    100% {
      transform: scaleY(1);
      opacity: 1;
    }
  }
}
%formControlStyles {
  .formControlWrapper {
    + .formControlWrapper {
      margin-top: $hydra-spacing-16;
    }
    .formControlLabel {
      font-size: $hydra-text-xs;
      font-weight: $hydra-font-medium;
      line-height: 1.5;
      color: $textColor6;
      margin-bottom: $hydra-spacing-06;
      display: block;
    }
  }
}
@mixin dropdownBackdrop {
  .dropdownBackdrop {
    background: transparent;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    transition: $hydra-transition-speed-3 all;
  }
}
@mixin dropdownCommonSlideup {
  .dropdownBackdrop {
    z-index: $hydra-zIndex-slide-panel-backdrop;
    animation: opacityFade $hydra-transition-speed-3 ease-in-out forwards;
  }
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: $hydra-zIndex-3;
  transform: translate(-50%, -50%);
  opacity: 1;
  width: 100%;
  min-width: 100%;
  height: 100%;
  background: transparent;
  .dropdownContent {
    position: fixed;
    left: 50%;
    top: 50%;
    z-index: $hydra-zIndex-slide-panel;
    transform: translate(-50%, -50%);
    box-shadow: 0 $hydra-spacing-04 $hydra-spacing-12 rgb(0 0 0 / 25%);
    border-radius: 0.25rem;
    background-color: $white;
    max-width: 16.5625rem;
    width: 100%;
  }
}
@mixin dropdownCustomStyles {
  .dropdownCustom {
    position: absolute;
    right: 0;
    z-index: $hydra-zIndex-slide-panel;
    box-shadow: 0 $hydra-spacing-04 $hydra-spacing-12 rgba(0, 0, 0, 0.25);
    border-radius: $hydra-radius-4;
    background-color: $white;
    min-width: 16.5625rem;
    max-width: 16.5625rem;
    opacity: 0;
    &.mobFloat {
      @include dropdownBackdrop;
      @include mediaQuery("lg") {
        @include dropdownCommonSlideup;
      }
    }
    &.mobilePanel,
    &.onlyMobilePanel {
      @include dropdownBackdrop;
      @include mediaQuery("lg") {
        @include dropdownCommonSlideup;
        .dropdownContent {
          .dropdownHeader {
            min-height: 4.25rem;
            padding: $hydra-spacing-16 $hydra-spacing-20;
            .title {
              font-size: $hydra-text-base;
            }
          }
          .dropdownBody {
            padding: $hydra-spacing-24;
            &.optionList {
              padding: $hydra-spacing-16 0 $hydra-spacing-40;
              max-height: calc(100vh - 573px);
              overflow: auto;
              min-height: 15rem;
              .dropdownList {
                li {
                  .dpItem {
                    padding: $hydra-spacing-18 $hydra-spacing-20;
                    font-size: $hydra-text-base;
                    line-height: 1.5;
                    font-weight: $hydra-font-regular;
                    width: 100%;
                    text-align: left;
                    color: $textColor8;
                    transition: $hydra-transition-speed-3 all;
                    &:disabled {
                      opacity: 0.4;
                    }
                    &:hover,
                    &.selected {
                      background-color: $primary600;
                    }
                  }
                }
              
              }
            }
            .formControlWrapper {
              .formControlLabel {
                font-size: $hydra-text-sm;
              }
            }
          }
          .dropdownFooter {
            .buttonGroupRight {
              width: 100%;
              button {
                width: 100%;
                border-radius: $hydra-radius-26;
                padding: $hydra-spacing-12;
                font-size: $hydra-text-sm;
              }
            }
          }
        }
      }
    }
    &.onlyMobilePanel {
      @include mediaQuery(lg, min-width) {
        display: none;
      }
    }
    &.profileDropdown {
      .dropdownContent {
        position: relative;
        @include mediaQuery(lg) {
          box-shadow: 0px 0px 1px rgb(10 31 68 / 10%),
            0px 26px 26px rgb(10 31 68 / 12%);
          border-radius: $hydra-radius-8;
          max-width: 20.4375rem;
          .dropdownHeader {
            align-items: flex-start;
          }
        }
        &:before {
          content: "";
          position: absolute;
          top: -$hydra-spacing-16;
          right: $hydra-spacing-04;
          border: $hydra-spacing-09 solid $white;
          width: $hydra-spacing-18;
          height: $hydra-spacing-18;
          border-left: $hydra-spacing-09 solid transparent;
          border-right: $hydra-spacing-09 solid transparent;
          border-top: transparent;
          @include mediaQuery(lg) {
            display: none;
          }
        }
      }
      .dropdownHeader {
        margin: 0 $hydra-spacing-16;
        padding-left: 0;
        padding-right: 0;
        @include mediaQuery(lg, min-width) {
          .actiongroup {
            display: none;
          }
        }
        .title {
          @extend %flex-a-item-start;
          min-width: 0;
          @include column-gap($hydra-spacing-08);
          .circleProfile {
            width: 2.125rem;
            height: 2.125rem;
            @extend %flexShrink-0;
            span{
              width: 2.125rem;
              height: 2.125rem;
              overflow: hidden;
              min-width:  2.125rem;
              img{
                width: 2.125rem;
                height: 2.125rem;
                object-fit: cover;
                min-width:  2.125rem;
              }
            }
            
          }
          .ProfileDetails {
            @extend %flex-a-item-start;
            @extend %flex-column;
            min-width: 0;
            .ProfileName,
            .ProfileEmail {
              font-size: $hydra-text-xs;
              line-height: 1.5;
              @extend %truncate;
              width: 100%;
              text-align: left;
              text-transform: none;
            }

            .ProfileName {
              font-weight: $hydra-font-semibold;
              color: $textColor8;
            }
            .ProfileEmail {
              font-weight: $hydra-font-regular;
              color: $textColor9;
            }
          }
        }
      }
      .dropdownBody {
        padding: 0;
      }
      .profileList {
        max-height: 20rem;
        overflow: auto;
        li {
          overflow: hidden;
          &:first-child {
            .ProfileLink {
              padding-top: $hydra-spacing-16;
              @include mediaQuery(lg) {
                margin-top: $hydra-spacing-10;
              }
            }
          }
          &:last-child {
            .ProfileLink {
              padding-bottom: $hydra-spacing-16;
              @include mediaQuery(lg) {
                margin-bottom: $hydra-spacing-14;
              }
            }
          }
          .ProfileLink {
            font-size: $hydra-text-xs;
            line-height: 1.5;
            color: $textColor8;
            text-align: left;
            font-weight: $hydra-font-regular;
            @extend %flex-a-item-start;
            padding: $hydra-spacing-12 $hydra-spacing-24;
            &:hover {
              background-color: $white200;
            }
            &.logoutLink {
              color: $textRedColor;
            }
          }
        }
      }
    }
    &.bottom,
    &.top {
      @include mediaQuery("lg") {
        &.mobilePanel,
        &.onlyMobilePanel {
          animation: opacityFade $hydra-transition-speed-3 ease-in-out forwards;
          .dropdownContent {
            left: 0;
            top: auto;
            bottom: 0;
            transform: translateY(100%);
            max-width: 100%;
            animation: slideUp $hydra-transition-speed-3 ease-in-out forwards;
          }
        }
        &.mobFloat {
          animation: none;
          top: 50%;
        }
      }
    }
    @extend %dropdownPrefixedPositions;
  }
  .dropdownContent {
    .dropdownHeader {
      padding: $hydra-spacing-16;
      @extend %flex-a-item-center;
      border-bottom: $hydra-dim-01 solid $borderShade1;
      .title {
        text-transform: capitalize;
        font-size: $hydra-text-xs;
        font-weight: $hydra-font-semibold;
        line-height: 1.5;
        color: $textColor5;
      }
      .actiongroup {
        margin-left: auto;
        @extend %flex-a-item-center;
        .closeBtn {
          color: $gray500;
          svg {
            width: $hydra-dim-20;
            height: $hydra-dim-20;
          }
          &:hover {
            color: $black;
          }
        }
      }
    }
    .dropdownBody {
      padding: $hydra-spacing-16 $hydra-spacing-20;
      @extend %formControlStyles;
    }
    .dropdownFooter {
      @extend %flex-a-item-center;
      padding: $hydra-spacing-16;
      @include mediaQuery(lg) {
        padding: $hydra-spacing-24 $hydra-spacing-20;
      }
      .buttonGroupRight {
        margin-left: auto;
        @extend %flex;
        @extend %flex-wrap;
        @include grid-gap($hydra-spacing-08);
        button {
          @extend %flex-a-j-center;
        }
      }
    }
  }
}
@mixin cardStyles {
  .card {
    border: $hydra-dim-01 solid $borderShade1;
    border-radius: $hydra-radius-10;
    padding: $hydra-spacing-16 $hydra-spacing-16 $hydra-spacing-14
      $hydra-spacing-16;
    background-color: $white;
  }
  .cardEmpty {
    @extend %flex-a-j-center;
    min-height: 11.25rem;
  }
}
%searchBoxDP {
  .searchPanel {
    position: relative;
    width: 100%;
  }
  .dropdownSearch {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: $hydra-zIndex-dropdown;
    background-color: $white;
    box-shadow: 0 $hydra-spacing-03 $hydra-spacing-07 rgba(0, 0, 0, 0.17);
    border-radius: $hydra-radius-4;
    width: 100%;
    opacity: 0;
    @extend %dropdownPrefixedPositions;
    // display: none;  //remove this to control with js
    @include mediaQuery("lg") {
      &.dropdownSearch {
        position: fixed;
        top: 4.5rem;
        height: 100%;
      }
    }
    .dpList {
      @extend %flex-f-column;
      overflow: auto;
      max-height: 20rem;
      @include mediaQuery(lg) {
        max-height: calc(100% - 4.5rem);
      }
      .dpItem {
        + .dpItem {
          border-top: $hydra-dim-01 solid $borderShade1;
        }
        .dpLink {
          padding: $hydra-spacing-16;
          @extend %flex-a-item-start;
          transition: $hydra-transition-speed-3 all;
          &:hover {
            background-color: $white200;
          }
          .title {
            font-weight: $hydra-font-regular;
            font-size: $hydra-text-xs;
            line-height: 1.5;
            word-break: break-word;
            color: $textColor2;
            .matchedWord {
              color: $black;
            }
          }
          .category {
            color: $gray500;
            font-weight: $hydra-font-medium;
            font-size: $hydra-text-xxs;
            line-height: 1.2;
            margin-top: $hydra-spacing-03;
            margin-left: auto;
            padding-left: $hydra-spacing-10;
          }
        }
      }
    }
  }
}
@mixin circleProfileIcon {
  .circleProfile {
    margin-left: $hydra-spacing-04;
    background-color: $gray500;
    width: 1.75rem;
    height: 1.75rem;
    border-radius: $hydra-radius-full;
    padding: $hydra-spacing-06;
    font-weight: $hydra-font-semibold;
    line-height: 1.25;
    font-size: $hydra-text-xs;
    color: $white;
    position: relative;
  }
}

//microanimations
//arrow line on hover
%arrowLineExternalLine {
  svg {
    [class*="arrow-line"] {
      animation: arrowAnimation 1s forwards;
      @keyframes arrowAnimation {
        30% {
          transform: translate(-3px, 3px);
          opacity: 1;
        }
        50% {
          transform: translate(2px, -2px);
          opacity: 0.2;
        }
        80% {
          opacity: 0.6;
        }
        100% {
          transform: translate(0, 0);
          opacity: 1;
        }
      }
    }
  }
}

@mixin emptyCard {
  .noRecords {
    min-height: 3rem;
    background: $white;
    border: $hydra-dim-01 solid $borderShade5;
    border-radius: $hydra-radius-6;
    padding: $hydra-spacing-16;
    @extend %flex-a-j-center;
    width: 100%;
    font-size: $hydra-text-xs;
  }
}

///input box search
.formControl {
  position: relative;
  &:has(.formControlIconClear) {
    .formInput {
      padding-right: $hydra-spacing-40;
    }
  }
  .formControlIconPrefix {
    position: absolute;
    left: $hydra-spacing-17;
    top: $hydra-spacing-10;
    + .formInput {
      padding: $hydra-spacing-12 $hydra-spacing-16 $hydra-spacing-12
        $hydra-spacing-40;
    }
    svg {
      width: $hydra-dim-16;
      height: $hydra-dim-16;
      color: $gray500;
    }
  }
  .formControlIconClear {
    position: absolute;
    right: $hydra-spacing-17;
    top: $hydra-spacing-10;
    &:hover svg {
      color: $black;
    }
    svg {
      width: $hydra-dim-16;
      height: $hydra-dim-16;
      color: $gray500;
    }
  }
  .formInput {
    padding: $hydra-spacing-12 $hydra-spacing-12;
    border-radius: $hydra-radius-8;
    border: $hydra-dim-01 solid $gray600;
    background-color: $white200;
    font-size: $hydra-text-xs;
    line-height: 1.5;
    color: $black;
    font-weight: $hydra-font-regular;
    transition: $hydra-transition-speed-3 all;
    &::placeholder {
      color: $gray500;
    }
    &:disabled {
      opacity: 0.4;
      pointer-events: none;
    }
    &:focus {
      outline-color: $primary100;
    }
  }
}
%alertComponent {
  border-width: $hydra-dim-01;
  border-style: solid;
  padding: $hydra-spacing-11 $hydra-spacing-16;
  @extend %flex-a-item-start;
  @include column-gap($hydra-spacing-08);
  border-radius: $hydra-radius-8;
  filter: drop-shadow(
    0 $hydra-spacing-02 $hydra-spacing-04 rgba(0, 0, 0, 0.05)
  );
  .alertIcon {
    margin-top: $hydra-spacing-01;
    svg {
      width: $hydra-dim-20;
      height: $hydra-dim-20;
    }
  }
  .alertTitle {
    font-size: $hydra-text-xs;
    line-height: 2;
    font-weight: $hydra-font-regular;
    color: $textColor4;
  }
  .viewAll {
    margin-top: $hydra-spacing-06;
    margin-left: auto;
    font-size: $hydra-text-xs;
    line-height: 1.2;
    color: $primary100;
    font-weight: $hydra-font-medium;
  }
  &.success {
    background-color: $successBg;
    border-color: $successBorder;
    .alertIcon {
      svg {
        color: $success;
      }
    }
  }
}
%dropdownPrefixedPositions {
  &.bottom {
    animation: growDown $hydra-transition-speed-3 ease-in-out forwards;
    transform-origin: top center;
    top: calc(100% + $hydra-spacing-08);
  }
  &.top {
    animation: growUp $hydra-transition-speed-3 ease-in-out forwards;
    transform-origin: bottom center;
    bottom: calc(100% + $hydra-spacing-08);
  }
  @keyframes opacityFade {
    100% {
      opacity: 1;
      background: $backdrop;
    }
  }
  @keyframes slideUp {
    100% {
      transform: translateY(0);
    }
  }
  @keyframes growDown {
    0% {
      transform: scaleY(0);
    }
    80% {
      transform: scaleY(1.1);
      opacity: 0.65;
    }
    100% {
      transform: scaleY(1);
      opacity: 1;
    }
  }
  @keyframes growUp {
    0% {
      transform: scaleY(0);
    }
    80% {
      transform: scaleY(1.1);
      opacity: 0.65;
    }
    100% {
      transform: scaleY(1);
      opacity: 1;
    }
  }
}
%formControlStyles {
  .formControlWrapper {
    + .formControlWrapper {
      margin-top: $hydra-spacing-16;
    }
    .formControlLabel {
      font-size: $hydra-text-xs;
      font-weight: $hydra-font-medium;
      line-height: 1.5;
      color: $textColor6;
      margin-bottom: $hydra-spacing-06;
      display: block;
    }
  }
}
@mixin dropdownBackdrop {
  .dropdownBackdrop {
    background: transparent;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    transition: $hydra-transition-speed-3 all;
  }
}
@mixin dropdownCommonSlideup {
  .dropdownBackdrop {
    z-index: $hydra-zIndex-slide-panel-backdrop;
    animation: opacityFade $hydra-transition-speed-3 ease-in-out forwards;
  }
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: $hydra-zIndex-3;
  transform: translate(-50%, -50%);
  opacity: 1;
  width: 100%;
  min-width: 100%;
  height: 100%;
  background: transparent;
  .dropdownContent {
    position: fixed;
    left: 50%;
    top: 50%;
    z-index: $hydra-zIndex-slide-panel;
    transform: translate(-50%, -50%);
    box-shadow: 0 $hydra-spacing-04 $hydra-spacing-12 rgb(0 0 0 / 25%);
    border-radius: 0.25rem;
    background-color: $white;
    max-width: 16.5625rem;
    width: 100%;
  }
}
@mixin dropdownCustomStyles {
  .dropdownCustom {
    position: absolute;
    right: 0;
    z-index: $hydra-zIndex-slide-panel;
    box-shadow: 0 $hydra-spacing-04 $hydra-spacing-12 rgba(0, 0, 0, 0.25);
    border-radius: $hydra-radius-4;
    background-color: $white;
    min-width: 16.5625rem;
    max-width: 16.5625rem;
    opacity: 0;
    &.mobFloat {
      @include dropdownBackdrop;
      @include mediaQuery("lg") {
        @include dropdownCommonSlideup;
      }
    }
    &.mobilePanel,
    &.onlyMobilePanel {
      @include dropdownBackdrop;
      @include mediaQuery("lg") {
        @include dropdownCommonSlideup;
        .dropdownContent {
          .dropdownHeader {
            min-height: 4.25rem;
            padding: $hydra-spacing-16 $hydra-spacing-20;
            .title {
              font-size: $hydra-text-base;
            }
          }
          .dropdownBody {
            padding: $hydra-spacing-24;
            &.optionList {
              padding: $hydra-spacing-16 0 $hydra-spacing-40;
              max-height: calc(100vh - 573px);
              overflow: auto;
              min-height: 15rem;
              .dropdownList {
                li {
                  .dpItem {
                    padding: $hydra-spacing-18 $hydra-spacing-20;
                    font-size: $hydra-text-base;
                    line-height: 1.5;
                    font-weight: $hydra-font-regular;
                    width: 100%;
                    text-align: left;
                    color: $textColor8;
                    transition: $hydra-transition-speed-3 all;
                    &:disabled {
                      opacity: 0.4;
                    }
                    &:hover,
                    &.selected {
                      background-color: $primary600;
                    }
                  }
                }
              }
            }
            .formControlWrapper {
              .formControlLabel {
                font-size: $hydra-text-sm;
              }
            }
          }
          .dropdownFooter {
            .buttonGroupRight {
              width: 100%;
              button {
                width: 100%;
                border-radius: $hydra-radius-26;
                padding: $hydra-spacing-12;
                font-size: $hydra-text-sm;
              }
            }
          }
        }
      }
    }
    &.onlyMobilePanel {
      @include mediaQuery(lg, min-width) {
        display: none;
      }
    }
    &.profileDropdown {
      .dropdownContent {
        position: relative;
        @include mediaQuery(lg) {
          box-shadow: 0px 0px 1px rgb(10 31 68 / 10%),
            0px 26px 26px rgb(10 31 68 / 12%);
          border-radius: $hydra-radius-8;
          max-width: 20.4375rem;
          .dropdownHeader {
            align-items: flex-start;
          }
        }
        &:before {
          content: "";
          position: absolute;
          top: -$hydra-spacing-16;
          right: $hydra-spacing-04;
          border: $hydra-spacing-09 solid $white;
          width: $hydra-spacing-18;
          height: $hydra-spacing-18;
          border-left: $hydra-spacing-09 solid transparent;
          border-right: $hydra-spacing-09 solid transparent;
          border-top: transparent;
          @include mediaQuery(lg) {
            display: none;
          }
        }
      }
      .dropdownHeader {
        margin: 0 $hydra-spacing-16;
        padding-left: 0;
        padding-right: 0;
        @include mediaQuery(lg, min-width) {
          .actiongroup {
            display: none;
          }
        }
        .title {
          @extend %flex-a-item-start;
          min-width: 0;
          @include column-gap($hydra-spacing-08);
          .circleProfile {
            width: 2.125rem;
            height: 2.125rem;
            @extend %flexShrink-0;
            span{
              width: 2.125rem;
              height: 2.125rem;
              overflow: hidden;
              min-width:  2.125rem;
              img{
                width: 2.125rem;
                height: 2.125rem;
                object-fit: cover;
                min-width:  2.125rem;
              }
            }
          }
          .ProfileDetails {
            @extend %flex-a-item-start;
            @extend %flex-column;
            min-width: 0;
            .ProfileName,
            .ProfileEmail {
              font-size: $hydra-text-xs;
              line-height: 1.5;
              @extend %truncate;
              width: 100%;
              text-align: left;
              text-transform: none;
            }

            .ProfileName {
              font-weight: $hydra-font-semibold;
              color: $textColor8;
            }
            .ProfileEmail {
              font-weight: $hydra-font-regular;
              color: $textColor9;
            }
          }
        }
      }
      .dropdownBody {
        padding: 0;
      }
      .profileList {
        max-height: 20rem;
        overflow: auto;
        li {
          overflow: hidden;
          &:first-child {
            .ProfileLink {
              padding-top: $hydra-spacing-16;
              @include mediaQuery(lg) {
                margin-top: $hydra-spacing-10;
              }
            }
          }
          &:last-child {
            .ProfileLink {
              padding-bottom: $hydra-spacing-16;
              @include mediaQuery(lg) {
                margin-bottom: $hydra-spacing-14;
              }
            }
          }
          .ProfileLink {
            font-size: $hydra-text-xs;
            line-height: 1.5;
            color: $textColor8;
            text-align: left;
            font-weight: $hydra-font-regular;
            @extend %flex-a-item-start;
            padding: $hydra-spacing-12 $hydra-spacing-24;
            &:hover {
              background-color: $white200;
            }
            &.logoutLink {
              color: $textRedColor;
            }
          }
        }
      }
    }
    &.bottom,
    &.top {
      @include mediaQuery("lg") {
        &.mobilePanel,
        &.onlyMobilePanel {
          animation: opacityFade $hydra-transition-speed-3 ease-in-out forwards;
          .dropdownContent {
            left: 0;
            top: auto;
            bottom: 0;
            transform: translateY(100%);
            max-width: 100%;
            animation: slideUp $hydra-transition-speed-3 ease-in-out forwards;
          }
        }
        &.mobFloat {
          animation: none;
          top: 50%;
        }
      }
    }
    @extend %dropdownPrefixedPositions;
  }
  .dropdownContent {
    .dropdownHeader {
      padding: $hydra-spacing-16;
      @extend %flex-a-item-center;
      border-bottom: $hydra-dim-01 solid $borderShade1;
      .title {
        text-transform: capitalize;
        font-size: $hydra-text-xs;
        font-weight: $hydra-font-semibold;
        line-height: 1.5;
        color: $textColor5;
      }
      .actiongroup {
        margin-left: auto;
        @extend %flex-a-item-center;
        .closeBtn {
          color: $gray500;
          svg {
            width: $hydra-dim-20;
            height: $hydra-dim-20;
          }
          &:hover {
            color: $black;
          }
        }
      }
    }
    .dropdownBody {
      padding: $hydra-spacing-16 $hydra-spacing-20;
      @extend %formControlStyles;
    }
    .dropdownFooter {
      @extend %flex-a-item-center;
      padding: $hydra-spacing-16;
      @include mediaQuery(lg) {
        padding: $hydra-spacing-24 $hydra-spacing-20;
      }
      .buttonGroupRight {
        margin-left: auto;
        @extend %flex;
        @extend %flex-wrap;
        @include grid-gap($hydra-spacing-08);
        button {
          @extend %flex-a-j-center;
        }
      }
    }
  }
}
@mixin cardStyles {
  .card {
    border: $hydra-dim-01 solid $borderShade1;
    border-radius: $hydra-radius-10;
    padding: $hydra-spacing-16 $hydra-spacing-16 $hydra-spacing-14
      $hydra-spacing-16;
    background-color: $white;
  }
  .cardEmpty {
    @extend %flex-a-j-center;
    min-height: 11.25rem;
  }
}
%searchBoxDP {
  .searchPanel {
    position: relative;
    width: 100%;
  }
  .dropdownSearch {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: $hydra-zIndex-dropdown;
    background-color: $white;
    box-shadow: 0 $hydra-spacing-03 $hydra-spacing-07 rgba(0, 0, 0, 0.17);
    border-radius: $hydra-radius-4;
    width: 100%;
    opacity: 0;
    @extend %dropdownPrefixedPositions;
    // display: none;  //remove this to control with js
    @include mediaQuery("lg") {
      &.dropdownSearch {
        position: fixed;
        top: 4.5rem;
        height: 100%;
      }
    }
    .dpList {
      @extend %flex-f-column;
      overflow: auto;
      max-height: 20rem;
      @include mediaQuery(lg) {
        max-height: calc(100% - 4.5rem);
      }
      .dpItem {
        + .dpItem {
          border-top: $hydra-dim-01 solid $borderShade1;
        }
        .dpLink {
          padding: $hydra-spacing-16;
          @extend %flex-a-item-start;
          transition: $hydra-transition-speed-3 all;
          &:hover {
            background-color: $white200;
          }
          .title {
            font-weight: $hydra-font-regular;
            font-size: $hydra-text-xs;
            line-height: 1.5;
            word-break: break-word;
            color: $textColor2;
            .matchedWord {
              color: $black;
            }
          }
          .category {
            color: $gray500;
            font-weight: $hydra-font-medium;
            font-size: $hydra-text-xxs;
            line-height: 1.2;
            margin-top: $hydra-spacing-03;
            margin-left: auto;
            padding-left: $hydra-spacing-10;
          }
        }
      }
    }
  }
}
@mixin circleProfileIcon {
  .circleProfile {
    margin-left: $hydra-spacing-04;
    background-color: $gray500;
    width: 1.75rem;
    height: 1.75rem;
    border-radius: $hydra-radius-full;
    padding:0;
    font-weight: $hydra-font-semibold;
    line-height: 1.25;
    font-size: $hydra-text-xs;
    color: $white;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    span{
      width: 1.75rem;
      height: 1.75rem;
      flex: 0 0 1.75rem;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      img{
        width: 1.75rem;
        height: 1.75rem;
        object-fit: cover;
      }
    }
  }
}

//microanimations
//arrow line on hover
%arrowLineExternalLine {
  svg {
    [class*="arrow-line"] {
      animation: arrowAnimation 1s forwards;
      @keyframes arrowAnimation {
        30% {
          transform: translate(-3px, 3px);
          opacity: 1;
        }
        50% {
          transform: translate(2px, -2px);
          opacity: 0.2;
        }
        80% {
          opacity: 0.6;
        }
        100% {
          transform: translate(0, 0);
          opacity: 1;
        }
      }
    }
  }
}

@mixin emptyCard {
  .noRecords {
    min-height: 3rem;
    background: $white;
    border: $hydra-dim-01 solid $borderShade5;
    border-radius: $hydra-radius-6;
    padding: $hydra-spacing-16;
    @extend %flex-a-j-center;
    width: 100%;
    font-size: $hydra-text-xs;
  }
}
@mixin tabset {
  .tabsetTab {
    font-size: $hydra-text-xs;
    color: $textColor9;
    line-height: 1.5;
    font-weight: $hydra-font-regular;
    padding: $hydra-spacing-06 $hydra-spacing-16;
    position: relative;
    transition: $hydra-transition-speed-3 all;
    &:before {
      transition: $hydra-transition-speed-3 all;
      content: "";
      background-color: transparent;
      position: absolute;
      bottom: (-($hydra-spacing-06));
      left: 50%;
      width: 100%;
      max-width: 3.75rem;
      height: $hydra-spacing-03;
      transform: translateX(-50%);
      border-radius: $hydra-radius-10;
    }
    &.active {
      color: $primary100;
      font-weight: $hydra-font-medium;
      &:before {
        background-color: $primary100;
      }
    }
  }
}
%profileIcon {
  width: $hydra-spacing-28;
  height: $hydra-spacing-28;
  border-radius: $hydra-radius-full;
  @extend %flexShrink-0;
  background-color: $gray500;
  @extend %flex-a-j-center;
  color: $white;
  font-size: $hydra-text-xs;
  line-height: 1.5;
  font-weight: $hydra-font-semibold;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
%dottedSeparator {
  position: relative;
  &:before {
    content: "";
    background-color: $gray800;
    height: $hydra-dim-04;
    border-radius: $hydra-radius-full;
    width: $hydra-dim-04;
    position: absolute;
    left: (-($hydra-spacing-09));
  }
}
.RoleView{
  display: none;
}