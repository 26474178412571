@use '../../assets/css/index.scss' as *;

.coreConsole {
  background-color: $primary300;
  padding: 0;
  width: 100%;
  overflow: auto;
  @extend %flex-f-column;
  @include column-gap($hydra-spacing-12);
  height: 100%;
  @include mediaQuery('lg') {
    padding: 0;
    background-color: $white;
    height: auto;
    overflow: auto;
    flex-direction: column;
    margin-bottom: 6.25rem;
  }
  .coreHeader {
    background-color: $white;
    padding: $hydra-spacing-16;
    @extend %flex-a-item-center;
    border-bottom: $hydra-dim-01 solid $borderShade4;
    @include mediaQuery(lg) {
      padding: $hydra-spacing-16 $hydra-spacing-20;
    }
    h3 {
      font-weight: $hydra-font-semibold;
      font-size: $hydra-text-sm;
      color: $textColor8;
    }

    .coreButton {
      margin-left: auto;
      @include mediaQuery(lg) {
        &.hideMobileBtn {
          display: none;
        }
      }
      @include mediaQuery(lg, min-width) {
        &.hideDvBtn {
          display: none;
        }
      }
      .viewAll {
        @extend %flex;
        @include column-gap($hydra-spacing-08);
        margin-left: auto;
      }
      @include mediaQuery(lg) {
        position: fixed;
        background-color: $white;
        bottom: 0;
        left: 0;
        z-index: $hydra-zIndex-1;
        border: 1px solid $borderShade4;
        box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.09);
        padding: $hydra-spacing-24 $hydra-spacing-20 $hydra-spacing-32;
        width: 100%;

        .viewAll {
          width: 100%;
          font-size: $hydra-text-sm;
          display: flex;
          align-items: center;
          justify-content: center;
          min-height: 2.75rem;
        }
      }
    }
  }
  .coreCard {
    height: calc(100vh - 7.1875rem);
    padding: $hydra-spacing-16;
    @include mediaQuery(lg) {
      padding: 0;
      border: 0;
      height: 100%;
      display: flex;
      flex-direction: column;
      > .card {
        border: 0;
        background-color: $primary300;
        height: 100%;
        @include mediaQuery(lg) {
          height: auto;
        }
      }
    }
  }
}
.coreSplit {
  @extend %grid;
  height: 100%;
  overflow: auto;
  @include grid-temp-columns(calc(50% - $hydra-spacing-16) calc(50% - $hydra-spacing-16));
  column-gap: 9px;
  @include mediaQuery(lg) {
    @include grid-temp-columns(1fr);
    overflow: visible;
  }
  .personalDetails {
    height: 100%;
    overflow: auto;
    padding-right: 9px;
    @include mediaQuery(lg) {
      overflow: visible;
    }
    .coreDetails {
      @extend %flex-f-column;
      @include row-gap($hydra-spacing-16);
      .card {
        transition: border-color $hydra-transition-speed-3;
      }
      &.editModeOn {
        .card,
        .formInput {
          border-color: $gray500;
        }
      }
      .subscription {
        background-color: $primary600;
        border: $hydra-dim-01 solid $textColor2;
        @extend %flex-a-item-center;
        @include mediaQuery(lg) {
          align-items: flex-start;
          flex-direction: column;
        }
      }
      .individualsCard {
        @media screen and (max-width: 1400px) {
          flex-direction: column;
          align-items: flex-start;
        }
      }
      .individualsCardSubRight {
        gap: 12px;
        display: flex;
        flex-direction: column;
        @media screen and (max-width: 1400px) {
          margin-left: 0 !important;
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 12px;
          margin-top: 16px;
        }

        @media screen and (max-width: 400px) {
          flex-direction: column;
        }
        button {
          margin: 0;
          @media screen and (max-width: 591px) {
            font-size: 12px !important;
          }
        }
      }
      .cardSubLeft {
        padding-right: 16px;
        .cardSubTitle {
          font-size: $hydra-text-sm;
          font-weight: $hydra-font-semibold;
          line-height: 1.5;
          color: $textColor8;
          margin-bottom: $hydra-spacing-08;
          @include mediaQuery(lg) {
            font-size: $hydra-text-sm;
          }
        }
        .cardSubExp {
          font-size: $hydra-text-xs;
          font-weight: $hydra-font-regular;
          line-height: 1.5;
          color: $textColor8;
          @include mediaQuery(lg) {
            font-size: $hydra-text-sm;
          }
        }
      }
      .cardSubRight {
        margin-left: auto;
        @include mediaQuery(lg) {
          width: 100%;
          margin-top: $hydra-spacing-24;
          button {
            width: 100%;
            display: flex;
            min-height: 2.25rem;
            align-items: center;
            justify-content: center;
            font-size: $hydra-text-sm;
          }
        }
      }
      .address {
        p {
          font-size: $hydra-text-xs;
          color: $textColor8;
          line-height: 1.5;
          font-weight: $hydra-font-regular;
          @include mediaQuery(lg) {
            font-size: $hydra-text-sm;
          }
        }
      }
      .addressWrapper {
        @extend %flex-f-column;
        @include row-gap($hydra-spacing-16);
        .addRow {
          @extend %grid;
          @include grid-temp-columns(repeat(auto-fit, minmax(calc(50% - $hydra-spacing-16), 1fr)));
          @include column-gap($hydra-spacing-16);
          @include mediaQuery(lg) {
            @include grid-temp-columns(1fr);
            @include row-gap($hydra-spacing-16);
          }
          .formInput,
          [class*='hydra-dropdown__control'] {
            min-height: 2.625rem;
            background-color: $white;
          }
          textarea.formInput {
            @include mediaQuery(lg) {
              min-height: 6rem;
            }
          }
        }
      }
      .payment {
        @extend %flex-a-item-center;
        @include mediaQuery(lg) {
          flex-direction: column;
          align-items: flex-start;
        }
        .paymentWrapper {
          @extend %flex-f-column;
          .cardDetails {
            @extend %flex-a-item-center;
            @include column-gap($hydra-spacing-19);
          }
          .editCard {
            @extend %flex-a-item-center;
            @include column-gap($hydra-spacing-19);
            .defaulted {
              font-size: $hydra-text-xs;
              color: $textColor9;
              line-height: 1.5;
              font-weight: $hydra-font-regular;
            }
            .editCardItem {
              @extend %flex-a-item-center;
              @include mediaQuery(lg) {
                margin-top: $hydra-spacing-08;
                button {
                  font-size: $hydra-text-sm;
                }
              }
              + .editCardItem {
                @extend %dottedSeparator;
                &:before {
                  left: (-($hydra-spacing-11));
                }
              }
            }
          }
        }
        .clientName {
          @extend %truncate;
          font-size: $hydra-text-sm;
          font-weight: $hydra-font-medium;
          color: $textColor8;
          max-width: 6rem;
        }
        .cardNumber {
          font-size: $hydra-text-sm;
          font-weight: $hydra-font-medium;
          color: $textColor9;
          @extend %flex-a-item-center;
          @extend %dottedSeparator;
        }
        .cardType {
          width: 4.0625rem;
          height: 2rem;
          border-radius: $hydra-radius-4;
          border: $hydra-dim-01 solid $borderShade1;
          background-color: $white;
          margin-left: auto;
          @include mediaQuery(lg) {
            margin-left: 0;
            margin-top: $hydra-spacing-12;
          }
          img {
            height: 100%;
            object-fit: contain;
            width: 100%;
            max-width: 2.6875rem;
            display: block;
            margin: 0 auto;
          }
        }
      }
    }
    .upcomingSubInvoiceCard {
      .subBody {
        @extend %flex-f-column;
        @include row-gap($hydra-spacing-16);
        .subRow {
          @extend %flex;
          align-items: center;
          .subCap {
            font-size: $hydra-text-sm;
            font-weight: $hydra-font-regular;
            color: $textColor9;
            max-width: calc(100% - 100px);
          }
          .subTotal {
            font-size: $hydra-text-sm;
            font-weight: $hydra-font-semibold;
            color: $textColor9;
            max-width: calc(100% - 100px);
          }
          .subValue {
            margin-left: auto;
            font-size: $hydra-text-sm;
            color: $textColor8;
            font-weight: $hydra-font-medium;
            &.price {
              font-weight: $hydra-font-semibold;
            }
            &.cardPaid {
              @extend %flex-a-item-center;
              @include column-gap($hydra-spacing-08);
              .cardLogo {
                border-radius: $hydra-radius-4;
                background-color: $white;
                padding: $hydra-spacing-03 $hydra-spacing-06;
                border: $hydra-dim-01 solid $borderShade1;
                img {
                  width: 2rem;
                }
              }
              .cardNumber {
              }
            }
          }
        }
      }
    }
  }
  .invoicesDetails {
    height: 100%;
    overflow: auto;
    padding-right: 9px;
    @extend %flex-f-column;
    @include row-gap($hydra-spacing-16);
    @include mediaQuery(lg) {
      overflow: visible;
      margin-top: $hydra-spacing-24;
    }
    .coreDTitle {
      margin-bottom: $hydra-spacing-16;
    }
    .subInvoiceCard {
      background-color: $white300;
      @include mediaQuery(lg) {
        background-color: $white;
      }
      .subHeader {
        padding-top: $hydra-spacing-06;
        @extend %flex-a-item-center;
        padding-bottom: $hydra-spacing-20;
        border-bottom: $hydra-dim-01 solid $borderShade4;
        .subTitle {
          font-size: $hydra-text-sm;
          font-weight: $hydra-font-semibold;
          line-height: 1.5;
          color: $textColor8;
        }
        .btnWrapper {
          margin-left: auto;
          @include mediaQuery(lg) {
            button {
              font-size: $hydra-text-sm;
            }
          }
        }
        @include mediaQuery(lg) {
          padding: 0 0 $hydra-spacing-16;
        }
      }
      .subBody {
        padding-top: $hydra-spacing-16;
        @extend %flex-f-column;
        @include row-gap($hydra-spacing-16);
        .subRow {
          @extend %flex;
          .subCap {
            font-size: $hydra-text-sm;
            font-weight: $hydra-font-regular;
            color: $textColor9;
          }
          .subValue {
            margin-left: auto;
            font-size: $hydra-text-sm;
            color: $textColor8;
            font-weight: $hydra-font-medium;
            &.price {
              font-weight: $hydra-font-semibold;
            }
            &.cardPaid {
              @extend %flex-a-item-center;
              @include column-gap($hydra-spacing-08);
              .cardLogo {
                border-radius: $hydra-radius-4;
                background-color: $white;
                padding: $hydra-spacing-03 $hydra-spacing-06;
                border: $hydra-dim-01 solid $borderShade1;
                img {
                  width: 2rem;
                }
              }
              .cardNumber {
              }
            }
          }
        }
      }
    }
  }
}
.personalDetails {
  @extend %flex-f-column;
  @include row-gap($hydra-spacing-24);
}
.coreTitle {
  font-weight: $hydra-font-semibold;
  color: $textColor8;
  line-height: 1.5;
  font-size: $hydra-text-sm;
}
.coreCardWrapper {
  .coreDTitle {
    margin-bottom: $hydra-spacing-16;
    @extend %flex-a-item-center;
    @include mediaQuery(lg) {
      margin-bottom: $hydra-spacing-16;
    }
  }
  .coreBtnWrapper {
    margin-left: auto;
    @extend %flex;
    @include column-gap($hydra-spacing-08);
    &.coreMv {
      width: 100%;
      margin-top: $hydra-spacing-24;
      button {
        width: 100%;
        min-height: 2.25rem;
        font-size: $hydra-text-sm;
      }
      @include mediaQuery(lg, min-width) {
        display: none;
      }
    }
    &.coreDv {
      @include mediaQuery(lg) {
        display: none;
      }
    }
    button {
      @extend %flex-a-j-center;
    }
  }
}

@include cardStyles;

.messagingWrapper {
  @include mediaQuery(lg) {
    padding: 0;
    overflow: auto;
    height: auto;
  }
}
@include mediaQuery(lg) {
  .dvOnly {
    display: none;
  }
}

@include mediaQuery(lg, min-width) {
  .mvOnly {
    display: none;
  }
}
