@use '../../assets/css/index.scss' as *;
.headerWrapper {
  background-color: $white;
  @extend %flex-a-item-center;
  padding: $hydra-spacing-08 $hydra-spacing-16;
  border-bottom: $hydra-dim-01 solid $borderShade1;
  height: 3.25rem;
  position: relative;
  z-index: 6;
  @include mediaQuery('lg') {
    background-color: $primary;
    padding: $hydra-spacing-24 $hydra-spacing-20;
    height: 4.5rem;
    .otherGrapMobile {
      overflow: hidden;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      .grapElement1 {
        width: 5rem;
        height: 5rem;
        left: -$hydra-spacing-22;
        right: auto;
        top: -$hydra-spacing-22;
        bottom: auto;
        position: absolute;
        border-radius: $hydra-radius-full;
        background: $primaryLight;
        opacity: 0.4;
        z-index: 0;
      }
      .grapElement2 {
        width: 5rem;
        height: 5rem;
        border-radius: $hydra-radius-full;
        background: $primaryLight;
        opacity: 0.4;
        position: absolute;
        bottom: $hydra-dim-16;
        bottom: -3.75rem;
        right: -$hydra-spacing-20;
        z-index: 0;
      }
    }
  }
  &.active {
    button {
      svg {
        path {
          transform: none !important;
          display: block !important;
        }
      }
    }
    .mobilePanelWrapper,
    .dropdownBackdrop {
      display: none !important;
    }
  }
}

.companyLogo {
  margin: 0 $hydra-spacing-16;
  order: 2;
  @include mediaQuery('lg') {
    .SearchMobileOn & {
      display: none;
    }
  }
  img,
  svg {
    width: 5rem;
    height: 1.75rem;
    object-fit: contain;
    --stop-color-end: #4361ee;
    --stop-color-dark: #000d2d;
    @include mediaQuery('lg') {
      --stop-color-end: #fff;
      --stop-color-dark: #fff;
    }
  }
}
.actionGroup {
  @extend %flex-a-item-center;
  @include column-gap($hydra-spacing-16);
  margin-left: auto;
  order: 4;
  @include mediaQuery('lg') {
    order: 2;
    .SearchMobileOn & {
      display: none;
    }
  }
  .commonActionbtn {
    width: $hydra-dim-20;
    height: $hydra-dim-20;
    svg {
      width: $hydra-dim-20;
      height: $hydra-dim-20;
      color: $gray500;
    }
    &.searchToggle {
      display: none;
    }
    @include mediaQuery('lg') {
      svg {
        color: $white;
      }
      &.searchToggle {
        display: block;
      }
      &.helpIcon {
        display: none;
      }
    }
  }
  @include circleProfileIcon;
}
.searchSection {
  @extend .formControl;
  @extend %searchBoxDP;
  width: 100%;
  order: 3;
  max-width: 18.875rem;
  @extend %flex;
  @include mediaQuery('lg') {
    max-width: 100%;
    margin-left: $hydra-spacing-20;
    z-index: $hydra-zIndex-1;
    &:not(.SearchMobileOn &) {
      display: none;
    }
  }
  .formInput {
    width: 100%;
    height: 2.25rem;
    max-width: 18.875rem;
    @include mediaQuery('lg') {
      max-width: 100%;
    }
  }
}
.navigation {
  transition: $hydra-transition-speed-3 all;
  .active & {
    width: 13.8125rem;
    @include mediaQuery('lg') {
      width: auto;
      .mobilePanelWrapper {
        transform: translateX(0);
      }
      .dropdownBackdrop {
        pointer-events: all;
        z-index: $hydra-zIndex-slide-panel-backdrop;
        animation: opacityFade $hydra-transition-speed-3 ease-in-out forwards;
      }
    }
  }
  .closeHeader {
    display: none;
  }
  @include mediaQuery('lg', 'min-width') {
    order: 1;
    position: fixed;
    left: 0;
    top: 0;
    width: 4.0625rem;
    height: 100%;
    background-color: $primary;
    padding: $hydra-spacing-12 $hydra-spacing-10;
    z-index: $hydra-zIndex-slide-panel;
  }
  @include mediaQuery('lg') {
    order: 1;
    display: flex;
    z-index: $hydra-zIndex-2;
    .dropdownBackdrop {
      background: transparent;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      transition: $hydra-transition-speed-3 all;
    }
    .closeHeader {
      border-bottom: $hydra-dim-01 solid $borderShade3;
      margin: 0 (-($hydra-spacing-08));
      padding: $hydra-spacing-12 $hydra-spacing-04 $hydra-spacing-24;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      svg {
        color: $white;
        width: 1.5rem;
        height: 1.5rem;
      }
    }
    .mobilePanelWrapper {
      position: fixed;
      left: 0;
      width: 100%;
      top: 0;
      max-width: 20.375rem;
      height: 100%;
      background-color: $primary;
      padding: $hydra-spacing-12 $hydra-spacing-10;
      z-index: $hydra-zIndex-header;
      transition: $hydra-transition-speed-3 all;
      transform: translateX(-100%);
      display: flex;
      flex-direction: column;
    }
  }
  .menuIcon:not(.menuIconClose) {
    color: $white;
    width: 1.5rem;
    height: 1.5rem;
    margin: 0 auto 0 $hydra-spacing-10;
    display: block;
    position: relative;
    @include mediaQuery('lg') {
      margin: 0;
    }
    svg {
      path {
        transition: $hydra-transition-speed-3 all;
      }
    }
    .active & {
      svg {
        path {
          &:nth-child(1) {
            transform: rotate(45deg) translate($hydra-spacing-06, -$hydra-spacing-13);
          }
          &:nth-child(2) {
            transform: scaleY(-1);
          }
          &:nth-child(3) {
            transform: rotate(-45deg) translate(-$hydra-spacing-11, 0);
          }
        }
      }
    }
  }
  .otherGrap {
    overflow: hidden;
    margin: 0 (-($hydra-spacing-10));
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    .grapElement1 {
      width: 2.3125rem;
      height: 2.3125rem;
      border-radius: $hydra-radius-full;
      background: $primaryLight;
      opacity: 0.4;
      position: absolute;
      bottom: 14.375rem;
      right: -$hydra-spacing-12;
    }
    .grapElement2 {
      width: 10.25rem;
      height: 10.25rem;
      border-radius: $hydra-radius-full;
      background: $primaryLight;
      opacity: 0.4;
      position: absolute;
      bottom: $hydra-dim-16;
      right: -7.25rem;
    }
  }
  .navList {
    @extend %flex;
    @extend %a-i-start;
    @extend %flex-column;
    margin-top: 2.75rem;
    @include row-gap($hydra-spacing-18);
    z-index: $hydra-zIndex-1;
    @extend %flex-j-start;
    &:not(.otherLinks) {
      height: 100%;
      overflow: auto;
    }
    &.otherLinks {
      display: none;
      margin-top: auto;
    }
    @include mediaQuery(lg) {
      @include row-gap($hydra-spacing-08);
      &.otherLinks {
        display: block;
      }
    }
    li {
      width: 100%;
      position: relative;
    }
    .navItem {
      padding: $hydra-spacing-12;
      border-radius: $hydra-radius-12;
      width: 2.75rem;
      height: 2.75rem;
      transition: $hydra-transition-speed-3 all;
      @extend %flex;
      overflow: hidden;
      @include column-gap($hydra-spacing-16);
      @include mediaQuery(lg) {
        padding: $hydra-spacing-18 $hydra-spacing-16;
        height: 3.5rem;
      }
      .active & {
        width: 100%;
        .navText {
          opacity: 1;
        }
       
      }
      .navText {
        font-size: $hydra-text-xs;
        font-weight: $hydra-font-semibold;
        line-height: 1.2;
        color: $white;
        @extend %truncate;
        margin-top: $hydra-spacing-04;
        opacity: 0;
      }
      .navCount {
        @apply absolute top-[2px] right-1 z-10 flex h-5 min-w-[20px] items-center justify-center rounded-full bg-red-500 text-xs text-white;
      }
      &.navActive,
      &:hover {
        background: linear-gradient(180deg, $primaryMedium 0%, $primaryDark 100%);
      }
      svg {
        color: $white;
        width: $hydra-dim-20;
        height: $hydra-dim-20;
        @extend %flexShrink-0;
      }
    }
  }
  &:hover {
    width: 13.8125rem;
    @include mediaQuery('lg') {
      width: auto;
      .mobilePanelWrapper {
        transform: translateX(0);
      }
      .dropdownBackdrop {
        pointer-events: all;
        z-index: $hydra-zIndex-slide-panel-backdrop;
        animation: opacityFade $hydra-transition-speed-3 ease-in-out forwards;
      }
    }
    .navList .navItem {
      width: 100%;
      span {
        opacity: 1;
      }
    }
    .menuIcon:not(.menuIconClose) {
      @apply pointer-events-none md:pointer-events-auto;
      svg {
        path {
          display: none;
          &:nth-child(1) {
            transform: rotate(45deg) translate($hydra-spacing-06, -$hydra-spacing-13);
          }
          &:nth-child(2) {
            transform: scaleY(-1);
          }
          &:nth-child(3) {
            transform: rotate(-45deg) translate(-$hydra-spacing-11, 0);
          }
        }
      }
    }
    .navItem .navCount {
      @apply opacity-0 hidden;
    }
  }
}
.profileWrapperBtn {
  position: relative;
}
